
.banner-item {
	position: relative;
	text-align: center;
}

.banner-item-content{
	position: relative;
	z-index: 10;
}

.banner-item img { 
	display: block;
	margin: 0 auto;
}

.banner-item-replace {
    left: 0;
    position: absolute;
    top: 0;
	right: 0;
    z-index: 0;
}

.home-banners,
.context-banners {
	padding: 5rem;
	display: flex;
	
	> div {
		display: flex;
		
		.banner-items{
			margin: 1rem;
		}
	}
}
 
.context-banners {  
	//background-image: url('#{$asset-path}images/context/bg/pub.jpg');
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: cover;
	justify-content: flex-end;
	
	> div {
		flex-direction: column;
	}
}

.home-banners {  
	justify-content: center;
	
	> div {	
		&:last-child {
			flex-direction: column;
		}
	}
	
}

@include media-breakpoint-down('xs') {
	.home-banners,
	.context-banners {
		padding: 2rem;
	}
}

@include media-breakpoint-down('sm') {
	.home-banners,
	.context-banners {
		flex-direction: column;
	}
	
	.home-banners {
		
		> div {	
			flex-direction: column !important;
		}
	}
}

@include media-breakpoint-down('lg') {
	.home-banners {
		flex-direction: column;
		> div {	
			&:last-child {
				flex-direction: row;
			}
		}
	}
}

@media (max-width: 1400px)   {
	.context-banners {
		//background-image: url('#{$asset-path}images/context/bg/pub-1000.jpg');
	}
}