.btn {
	display: inline-block;
	text-align: center;
	text-decoration: none !important;
	vertical-align: middle;
	user-select: none;
	border: none;
	box-sizing: border-box;
	padding: 1rem 2.5rem;
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 500;
	border-radius: 2rem;
	text-overflow: ellipsis;
	outline: none;
    cursor: pointer;
	transition: background 0.8s;
	background-position: center;
	background-color: transparent;
	box-shadow: none;
	letter-spacing: 1px;
	
	&::-moz-focus-inner {
		border: none;
	}

	/* Hover, Focus */
	&:focus,
	&.focus {
		text-decoration: none !important;
		outline: 0;
	} 

	&.disabled,
	&:disabled {
		opacity: 0.65;
		pointer-events: none;
	}

	&.btn-block {
		display: block;
		width: 100%;
	}
}

.btn-next {
	position:relative; 
	padding-right: 4rem;
	
	&:after {
		position:absolute;
		content:'';
		right: 1rem;
		background-image : url('data:image/svg+xml,<svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11.0858 7.75739L15.3284 12L11.0858 16.2427L9.67157 14.8285L12.5 12L9.67157 9.1716L11.0858 7.75739Z" fill="white"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z" fill="white"></path></svg>');
		background-position: 0 50%;
		background-repeat: no-repeat;
		transition: background-position .2s ease;
		width: 36px;
		height: 24px;
		transition: all .3s ease;
	}
	
	&:hover:after {
		background-position: 100% 50%;
	}
}

.btn-primary {
	background-color: $primary;
	color: $white !important; 

	&:hover, &:focus {
		background-color: $dark-primary;
	}

	&:active {
		background-color: $darker-primary;
	}
}

.btn-danger {
	background-color: $red;
	color: $white !important; 

	&:hover, &:focus {
		background-color: darken($red, 5);
	}

	&:active {
		background-color: darken($red, 10);
	}
}

.btn-outline-primary {
	background-color: none;
	border: 1px solid $primary;
	color: $primary; 

	&:hover, &:focus, &:active {
		border-color: $dark-primary;
		color: $dark-primary; 
	}
}

.btn-light {
	background-color: $white;
	color: $black !important; 
	border: 1px solid $stroke;
	
	&:hover {
		background-color: $light-grey;
	}

	&:active {
		background-color: darken($light-grey, 5);
	}
}