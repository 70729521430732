.payment-header {
	min-height: 300px;
	background-image: url('#{$asset-path}images/bg/payment.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	display:flex;
	align-items: center;
	justify-content: center;
	font-size: 3.75rem;
	font-weight: 700;
	font-style: oblique;
	color: #FFF;
	text-align: center;
	text-shadow: 1px 1px 1px rgba(0,0,0,.2);
}

.payment-measures {
	display:flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	div {
		display:flex;
		flex-direction: column;
		justify-content: center;
		margin: .5rem;
		font-weight: 500;
		font-size: .75rem;
		span.icon {
			border: 1px solid $stroke;
			border-radius: 1rem;
			font-size: 4rem;
			width: 6rem;
			height: 6rem;
			display:flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.payment-collapse {
	border: 1px solid $stroke;

	&:hover {
		border-color: darken($stroke, 10);
	}

	+ .payment-collapse {
		border-top: none;
	}

	button {
		background: $green;
		border: none;
		outline: none;
		display:block;
		width: 100%;
		text-align: left;
		background-image: $select_img_up_light;
		background-repeat: no-repeat;
		background-position: right 1rem center;
		padding: 1rem 2.5rem 1rem 1rem;
		box-shadow: none;
		color: $white;



		&.collapsed {
			background-image: $select_img_down;
			background-color: $gray-100;
			color: $black;
		}
	}

	.content {
		padding: 1rem;

		.collapse-edit {
			margin-bottom: 1rem;
		}

		.cadd-pack {
			margin: 2rem 0 !important;
		}
	}
}

.payment-method {
	border-radius: 3rem;
	border: 1px solid $stroke;
	padding: 1rem 2rem;
	margin-bottom: 1rem;
	cursor: pointer;

	.header {
		position: relative;
		padding-left: 3rem;;

		&:before {
			position:absolute;
			content: "\e910";
			font-family: "skiset";
			font-size: 2rem;
			line-height:1;
			color: $stroke;
			left: 0;
			top:50%;
			transform: translateY(-50%);
		}

		.title {
			font-weight: 500;
		}
		.methods {
			img {
				height: 1.5rem;
				width: auto;
				margin-top: .25rem;
				margin-right: .5rem;
			}
		}
	}
	.body {
		display: none;
		margin-top: 2rem;
	}

	&:hover {
		border-color: $green;
	}

	&.current {
		border-color: $green;

		.header:before {
			content: "\e911";
			color: $green;
		}

		.body {
			display: block;
		}
	}

}

.form-payment {
	width: 100%;
	overflow: hidden;


	.row {
		margin-right: -1rem;
		margin-left: -1rem;

		.col  {
			padding-right: 1rem;
			padding-left: 1rem;
		}
	}

	fieldset {
		margin: 0;
	}
}

.input-container {
	background-color: $white;
	border-width: 1px;
	border-style: solid;
	border-color: $stroke;
	border-radius: 2rem;
	padding:.5rem 2rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	height: 60px; // 3.625 + 2px for border
	position:relative;
	overflow: hidden;
	cursor: pointer;
	box-shadow: none;

	*:not(iframe):not(input) {
		cursor: pointer;
	}

	&.hosted-fields-valid-state {
		border-color: $green;
	}
	&.hosted-fields-invalid-state {
		border-color: $red;
	}

	label {
		display: block;
		margin: 0;
		color: $gray-900;
		font-size: .75rem;
		line-height: 1.5;
		font-weight: 500;
	}

	input {
		font-size: 1rem;
		line-height: 1.5rem;
		border: none;
		display: block;
		outline: none;
		width: 100%;
		padding:0;
		margin: 0;
		height: 1.5rem;
	}
}

.iframe-container {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100000;
	display: none;
	background: $white;

	&.open {
		display: flex;
		flex-direction: column;
	}
	.header {
		height: 100px;
		background: $white;

		position: relative;
		padding: 1.5rem 2rem;
		box-shadow: 0 0 6px 0 rgba(0,0,0,.1);

		img {
			width: 200px;
			height: 52px;
		}
	}

	.iframe {
		width: 100%;
		flex: 1;
		border: none;
		background: #FFF;
	}
}