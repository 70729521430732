.mapboxgl-ctrl-attrib {
	 display: none !important;
}
.mapboxgl-ctrl { 
 
	button {
		outline: none;

		&:focus {
			box-shadow: none;
		} 
 
		&.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFF'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
		}
		&.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFF'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
		} 
		&.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
			height: 18px;
			margin: 6px;
			width: 18px;
			background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='yes'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath fill='%23FFF'%0Ad='M5.5,14.2c-0.9,0.8-1.9,0-1.5-1l4-9c0.2-0.4,0.6-0.8,1-0.8s0.8,0.4,1,0.8l4,9c0.4,1-0.6,1.8-1.5,1l-3.5-3L5.5,14.2z'/%3E%3C/svg%3E");
		}
	}
}
.mapboxgl-ctrl-group { 
	border-radius: 8px;
	background: $green;

	button + button {
		border-top: 1px solid $white;
	}
}

.mapboxgl-canvas-container {
	z-index: 10;
}

.mapboxgl-popup {
	z-index: 15;
	width: 360px;

	button {
		font-size: 1.2rem;
		color: $black;
	}
 
	.mapboxgl-popup-content {
		box-shadow: 0 0 5px 2px rgba(0,0,0,.15);
		padding: 1rem;

		.title {
			font-size: 1rem;
		}
	}
}

.marker-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 36px;
	height: 50px;
	z-index: 1;
	
	
	.marker-illustration {
		width: 36px;
		height: 50px;
		background: transparent url('../../assets/images/mapbox/shop1.png') no-repeat top left;
	}
	
	.marker-name { 
		font-size: 0.875rem; 
		line-height: 1; 
		position: absolute;
		white-space: nowrap;
		bottom: 0; margin-bottom: -1.125rem;
	}
	
	&.current-small {
		z-index: 2;
		
		.marker-illustration {
			background: transparent url('../../assets/images/mapbox/shop2.png') no-repeat top left;
		}
	}
	
	&.current {
		width: 62px;
		height: 88px;
		z-index: 2;
		
		.marker-illustration {
			width: 62px;
			height: 88px;
			background: transparent url('../../assets/images/mapbox/shop.png') no-repeat top left;
		}
	}
}
#motor_map_shops {
	margin: 0 -2rem 1rem;
	
	.owl-carousel .owl-stage-outer .owl-stage {
		display: flex;
		align-items: flex-end;

		.owl-item {
			float: none;
		}
	}
}
.map-overlay {
	font: 1rem/1.5 $font-family-base;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 1.5rem;
	z-index:1000; 
	
	&.top {
		
		top: 0;
		bottom:auto;
	}
	
	&.bottom {
		bottom: 0;
		top:auto;
	}
	
	.map-overlay-inner {
		background-color: $white;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		border-radius: 1rem 1.5rem;
		padding: 1rem 1.5rem;
		border: 1px solid $green;
		margin: 0 .5rem;
	}
} 