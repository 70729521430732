// Desktop
// -----------------------------------------------------------------------------

.motor-closed {
	padding: 3rem 4rem;
	background: rgba($white,.8);
	text-align: center;
	//color: $red;
}
.motor-home , .motor-home-date {
	
	padding: 3rem 4rem;
	border-radius: .3rem;
	background: $white;	
	box-shadow: 0 4px 5px -2px rgba(0,0,0,.2);
	
	.body {
		flex: 1 1 0;
	}
	
	@include media-breakpoint-down('lg') {
		padding: 2rem 3rem;
	}
	@include media-breakpoint-down('sm') {
		padding: 2rem;
	}
}

.motor-home {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 520px;
	
	&.oct-rose {
		background-image: url('#{$asset-path}images/bg/lor.png');
		background-repeat: no-repeat;
		background-position: top right;
	}
	
	.title {
		font-size: 2rem;
		font-weight: 500;
		line-height: 1;
		margin-bottom: 2rem;
		
		span {
			color: $red;
		}
		
		@include media-breakpoint-down('sm') {
			font-size: 1.5rem;
		}
	}
	
	.validation {
		margin-top: 3rem;
	}
	
	.form-group {
		margin-bottom: .625rem;
		label, .control-label {
			color: $red !important;
		}
	}
}

.motor-home-date {
	position: absolute;
	top: 0; right: 2rem; left: 2rem; 
	margin: 0;
	z-index: 500;
	display: none;
	background: $white;
	min-height:100%;
	
	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 100%;
		height: 100%;
		flex-grow: 1;
		padding: 0;
	}
	
	.validation {
		margin: 1.5rem 0;
		text-align: center;
	}
	
	.explain {
		
		font-size: .875rem;
		color: $dark-grey;
		
		> div {
			font-size: 1rem;
			color: $dark;
			
			span.date {
				color: $red;
				font-weight: 500;
			}
		}
	}
	
	.litepicker {
		.container__months .month-item-header {
			padding-top: 0;
		}
		.container__days > a, .container__days > div {
			height: 2.5rem;
		}
	} 
	
	@include media-breakpoint-down('md') {
		.title span.date,
		.explain {
			display: none;
		}
	}
}

// Mobile
// -----------------------------------------------------------------------------

#motor_flip {
	visibility: hidden;
}
#motor_choice_start {
	display:flex;
	align-items: center;
	justify-content: center;
	
	span {
		color: $white;
		font-weight: 800;
		font-style: oblique;
		display: inline-block;
		margin-bottom: 1.5rem;
		text-transform: uppercase;
		text-align: center;
		line-height:1;
		
		+ span {
			font-size: 3.125rem;
		}
	}
}

#motor_choice_resort,
#motor_choice_shop,
#motor_choice_shopmap,
#motor_choice_date,
#motor_choice_skier {
	background: $white;	
	display: flex;
	flex-direction: column;
	
	.flip-body {
		.title {
			font-size: 2rem;
			font-weight: 700;
			line-height: 1.25;
			margin-bottom: .25rem; 

			span {
				color: $red;
			}
		}
		.sub-title {
			font-size: 1rem;
			font-weight: 700;
			line-height: 1.25;
			margin-bottom: 1rem; 
		}
	}
}

#motor_choice_resort .flip-header .form-group {
	flex : 1;
	margin:0;
	
	.form-control  {
		padding-left: 3.5rem;
		padding-right: 3.5rem;
		
		&::placeholder {
			color: #AAA !important; 
			opacity: 1  !important; 
		}
	}
	.control-label {
		left: 3.5625rem  !important; 
	}
	a.change-step {
		position: absolute;
		top: .5rem;
		right: 1.5rem;
		z-index: 10;
	}
	
	&:before {
		content: "\e90f";
		font-family: "skiset";
		font-size: 1.5rem;
		left: 1.5rem;
		top: .675rem;
		position: absolute;
		color: $dark-grey;
		z-index: 10;
	}
}

// -----------------------------------------------------------------------------

 
.flip-body-map {
	position:relative;
	overflow:hidden;
	flex: 1 1 auto;	
} 

// -----------------------------------------------------------------------------

.btn-motor-start {
	background:$white;
	outline: none;
	border:none;
	box-shadow: $shadow_100;
	font-weight: 500;
	color: $dark-grey;
	display:flex;		
	justify-content: center;
	align-items: center;
	border-radius: 3rem;
	padding: 1.5rem 5.5rem 1.5rem 1.5rem;
	position: relative;
	
	&:after {
		content: "\e913";
		font-family: "skiset";
		font-size: 1rem;
		top:.5rem;
		bottom:.5rem;
		right: .5rem;
		width: 3.5rem;
		height: 3.5rem;
		position: absolute;
		color: $white;
		background: $green;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3rem;
	}
}

// -----------------------------------------------------------------------------

.motor-result-item {
	cursor: pointer;	
	display: flex;
	border-bottom: 1px solid $stroke;
	justify-content: flex-start;
	align-items: center;
	
	.sk-card {
		padding: 1.5rem 1rem;
	}
	
	.card-content {
		flex:1;
		
		.icon {
			padding-left: 30px;
			position:relative;	
			display:block;

			&:before {
				font-family: "skiset";
				font-size: 1rem;
				left: 0;
				position: absolute;
				color: $dark-grey;
			}

			&.shop:before {
				content: "\e90a";
			}

			&.alt:before {
				content: "\e904";
			}
		}
	} 
	
	&.item-resort {
		
		&:before {
			content: "\e90f";
			font-family: "skiset";
			font-size: 1.5rem;
			width: 70px;
			height: 70px;
			background: $light-grey;
			border-radius: 8px;
			display:flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
		}
	}
	
	&.item-shop {
		
		> div {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0;
			
			img {
				max-width: 400px;
				height: auto;
				border-radius: .5rem;
			}
		}
		
		&:before {
			content: "\e910";
			font-family: "skiset";
			font-size: 2rem;
			width: 56px;
			height: 56px;
			color: $stroke;
			display:flex;
			justify-content: flex-start;
			align-items: center;
			flex-shrink: 0;
		}
		
		&.current:before {
			content: "\e911";
			color: $green;
		}
	}
}

// -----------------------------------------------------------------------------

#motor_shop_swap {
	margin:  1.5rem 0;
}

.motor-shop-swap {
	display:flex;
	background: $light-grey;
	border-radius: 3rem;
	justify-content:space-between;
	align-items: center;
	padding: .5rem;
	
	a {
		flex: 1;
		padding: 1rem;
		font-weight: 600;
		display:flex;		
		justify-content: center;
		align-items: center;
		color: $dark-grey;
		text-decoration: none;
		
		&:before {
			font-family: "skiset";
			font-size: 1.5rem;
			margin-right: .5rem;
			color: $dark-grey;
		}
		
		&.list:before {
			content: "\e912";
		}
		&.map:before {
			content: "\e94c";
		}
		
		&.current {
			background: $white;
			box-shadow: $shadow_100;
			border-radius: 3rem;
			color: $black;
			cursor: default !important;
			
			&:before {
				color: $black;
			}
		}
	}
}

// -----------------------------------------------------------------------------

.motor-count-skier {
	display:flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $stroke;
	padding:2rem 0;
	
	.type {
		font-weight: 700;
		font-size:1rem;
		
		em {
			display: block;
			color: $dark-grey;
			font-size:0.875rem;
			font-weight: 500;
			font-style: normal;
		}
	}
	
	.count {
		display:flex;
		align-items: center;
		
		
		* {
			color: $black;
		}
		
		span {
			font-size: 2rem;
			font-weight: 700;
			width: 2.5rem;
			text-align: center;
			
			&.active {
				color: $green;
			}
		}
		
		a {
			width: 3.5rem;
			height: 3.5rem;
			border: 1px solid $stroke;
			border-radius: 1.75rem;
			font-size: 1.5rem;
			display:flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
		}
	}
	
}

@media (max-width: 1000px) {
	.motor-result-item.item-shop {
		.sk-card {
			padding: 1rem 0 0 0;
		}

		> div {
			flex-direction: column;
			align-items: flex-start;
			padding: 1.5rem 0;

			img {
				width: 100%;
				max-width: 100%;
				flex: 1;
			}
		}
	}
}