.brand-box {
	margin-top: 2rem;
}

h3.brand {
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: auto 100%;
	
	&.brand-rossignol {background-image: url('#{$asset-path}images/brand/brand_1.png');}
	&.brand-dynastar {background-image: url('#{$asset-path}images/brand/brand_2.png');}
	&.brand-salomon {background-image: url('#{$asset-path}images/brand/brand_3.png');}
	&.brand-head {background-image: url('#{$asset-path}images/brand/brand_4.png');}
	&.brand-volkl {background-image: url('#{$asset-path}images/brand/brand_5.png');}
	&.brand-k2 {background-image: url('#{$asset-path}images/brand/brand_6.png');}
	&.brand-atomic {background-image: url('#{$asset-path}images/brand/brand_7.png');}
	&.brand-roxy {background-image: url('#{$asset-path}images/brand/brand_8.png');}
	&.brand-ride {background-image: url('#{$asset-path}images/brand/brand_9.png');}
	&.brand-burton {background-image: url('#{$asset-path}images/brand/brand_10.png');}
	&.brand-quicksilver {background-image: url('#{$asset-path}images/brand/brand_11.png');}
	&.brand-eco {background-image: url('#{$asset-path}images/brand/brand_12.png');}
	&.brand-nitro {background-image: url('#{$asset-path}images/brand/brand_13.png');}
	&.brand-lacroix {background-image: url('#{$asset-path}images/brand/brand_14.png');}
	&.brand-fischer {background-image: url('#{$asset-path}images/brand/brand_15.png');}
	&.brand-scott {background-image: url('#{$asset-path}images/brand/brand_16.png');}
	&.brand-nordica {background-image: url('#{$asset-path}images/brand/brand_17.png');}
	&.brand-volant {background-image: url('#{$asset-path}images/brand/brand_18.png');}
	&.brand-blizzard {background-image: url('#{$asset-path}images/brand/brand_19.png');}
	&.brand-dynamic {background-image: url('#{$asset-path}images/brand/brand_20.png');}
	&.brand-dynafit {background-image: url('#{$asset-path}images/brand/brand_21.png');}
	&.brand-tecnica {background-image: url('#{$asset-path}images/brand/brand_22.png');}
	&.brand-kastle {background-image: url('#{$asset-path}images/brand/brand_23.png');}
	&.brand-lange {background-image: url('#{$asset-path}images/brand/brand_24.png');}
	&.brand-northwave {background-image: url('#{$asset-path}images/brand/brand_25.png');}
	&.brand-bogner {background-image: url('#{$asset-path}images/brand/brand_26.png');}
	&.brand-indigo {background-image: url('#{$asset-path}images/brand/brand_27.png');}
	&.brand-blackcrows {background-image: url('#{$asset-path}images/brand/brand_28.png');}
	&.brand-dahu {background-image: url('#{$asset-path}images/brand/brand_29.png');}
	&.brand-dps {background-image: url('#{$asset-path}images/brand/brand_30.png');}
	&.brand-dalbello {background-image: url('#{$asset-path}images/brand/brand_31.png');}
	&.brand-exonde {background-image: url('#{$asset-path}images/brand/brand_32.png');}
	&.brand-armada {background-image: url('#{$asset-path}images/brand/brand_33.png');}
	&.brand-elan {background-image: url('#{$asset-path}images/brand/brand_34.png');}
	&.brand-zag {background-image: url('#{$asset-path}images/brand/brand_35.png');}
	&.brand-plum {background-image: url('#{$asset-path}images/brand/brand_36.png');}
	&.brand-edl {background-image: url('#{$asset-path}images/brand/brand_37.png');}
}

.brand-content {
	padding: 2rem ;
	border-left: .25rem solid #eee;
	
	h4 {
		color: $red;
		margin-bottom: 2rem;
		
		span {
			color: $black;
		}
	}
}

.brand-item {
	margin-bottom: 2rem;
	
	img {
		margin-left:-10px;
		margin-right: -10px;
	}
	
	.name {
		font-weight: 500;
		color: $black;
	} 
} 

/* accessoires
---------------*/

div.accessory { 
	margin-bottom: 2rem;
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
	
	a {
		border: 2px solid #EFEFEF; margin: .5rem;
		
		&:hover {
			border: 2px solid #ddd;
		}
	}
}