div.breadcrumb {
	background-color: $gray-100;
}

ul.breadcrumb {
	display: flex;
	flex-wrap: nowrap;
	list-style-type: none;
	max-width: 100%;
	overflow-x: auto;
	margin: 0;
	padding: 0;
		
	li {
		position: relative;
		margin: 0;
		padding: 1rem .5rem;
		white-space: nowrap;
		font-size: .875rem;
		
		&:first-child {
			padding-left: 0;
		}
		
		&:after { 
			content: "\e900";
			font-family: "skiset";
			font-size: .6rem;
			padding-left: .8rem;
			color: $dark-grey;
		}
		
		&:last-child:after  {
			content: none;
		}
		
		a {
			text-decoration: none;
			color:$dark-grey;
			vertical-align: middle;
			font-size: .875rem;
			
			&:hover {
				text-decoration: underline;
			}
		}
		
		
		&.disabled a {
			color: rgba(#FFF, 0.4);
		}
	}
}
