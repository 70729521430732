.lokki-header {
	background-image: url('#{$asset-path}images/statics/lokki-header.jpg') ;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 5rem;
	border-radius: 2rem;
	min-height: 380px;
	margin: 0 -5rem;
	
	.title {
		background: #2EBCC6;
		color:$white;
		font-size: 1.25rem;
		text-transform: uppercase;
		display: inline-block;
		padding: .5rem 1rem;
	}
}
a.lokki {
	display: block;
	padding: 1rem 2rem;
	background: #F8F9FA;
	border-radius: 6px;
	color: $black;
	text-decoration: none;

	&:after {
		background-image : url('data:image/svg+xml,<svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11.0858 7.75739L15.3284 12L11.0858 16.2427L9.67157 14.8285L12.5 12L9.67157 9.1716L11.0858 7.75739Z" fill="gray"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z" fill="gray"></path></svg>') !important;
	}

	&:hover {
		background: #2EBCC6;
		color: $white;

		&:after {
			background-image : url('data:image/svg+xml,<svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11.0858 7.75739L15.3284 12L11.0858 16.2427L9.67157 14.8285L12.5 12L9.67157 9.1716L11.0858 7.75739Z" fill="white"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z" fill="white"></path></svg>') !important;
		}
	}
} 