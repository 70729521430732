select.selectize {
	visibility: hidden;
}

// ------------------------------------------------------------------

.selectize-control {
	position: relative;
	line-height: 0 !important;
	font-size: 0 !important;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
	color: $black;
	font-family: $font-family-base;
	font-size: 1rem;
	line-height:  1.5;
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
	background: $white;
	cursor: text;
	display: inline-block;
}

.selectize-input { 
	border-width: 1px;
	border-style: solid;
	border-color: $stroke;
	border-radius: 2rem;
	padding:1.5rem 3rem .5rem 2rem;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	z-index: 1;
	@include style-box-sizing(border-box); 
	
	&::before {
		content: ' ';
		height: 2px; 
		width: 0;
		left: 50%;
		bottom: -1px;
		position: absolute;
		background: $green;
		@include style-transition((left 0.28s ease, width 0.28s ease));
		z-index: 2; 
	}
	
	.has-error & {
		border-radius: 0;
	}
	.form-light & {
		border: none;
		border-bottom: 1px solid $stroke;
		border-radius: 0;
		padding-left: .375rem;
	}

	&.dropdown-active, .selectize-control.single &.dropdown-active {
		border-radius : 0;
		border-color: transparent;
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
		
		&::before { 
			width: 100%;
			left: 0;
		}
	} 

	.form-light &.dropdown-active, .form-light .selectize-control.single &.dropdown-active {
		box-shadow: none;
	}
 
	.selectize-control.multi &.has-items { 
		padding: 1.75rem 2rem .25rem 2rem;

		.form-light & {
			padding-left: .375rem;
			padding-right: .375rem;
		}
	}

	&.full {
		background-color: $white;
	}

	&.disabled, &.disabled * {
		cursor: default !important;
	} 

	> * {
		vertical-align: baseline;
		display: -moz-inline-stack;
		display: inline-block;
		zoom: 1;
		*display: inline;
	}

	.selectize-control.multi & > div {
		cursor: pointer;
		margin: 0 .25rem .25rem 0;

		padding: 0 .5rem;
		background: $green;
		color: $white;

		&.active {
			background: $dark-primary;
			color: $white;
		}
	}

	.selectize-control.multi &.disabled > div {
		&, &.active {
			color: 	#CCC;
			background: $light-grey;
		}
	}
	
	> input {
		&::-ms-clear {
			display: none;
		}
		display: inline-block !important;
		padding: 0 !important;
		min-height: 0 !important;
		max-height: 24px !important;
		max-width: 100% !important;
		margin: 0 !important;
		text-indent: 0 !important;
		border: 0 none !important;
		background: none !important;
		line-height: 1.5 !important; 
		-webkit-user-select: auto !important;
		@include style-box-shadow(none !important) ;
		box-shadow: none !important;

		&:focus { outline: none !important; }

		&::placeholder {
			color: #AAA !important;
			opacity: 1;
		}
		
		.has-error.form-group & {
			box-shadow: none !important;
		}
	}

	&::after {
		content: ' ';
		display: block;
		clear: left;
	}
}

.selectize-dropdown {
	position: absolute;
	z-index: 10;
	background: $white;
	border-width: 1px;
	border-style: solid;
	border-color: $stroke;
	border-top: 0 none; 
	margin: 1px 0 0;
	 
	@include style-box-sizing(border-box);
	@include style-box-shadow(0 6px 12px rgba(0,0,0,.175));	

	[data-selectable] {
		cursor: pointer; 
		overflow: hidden;
		.highlight {
			background: $white;
			color: $red;
		}
	}
	
	.option, .optgroup-header { 
		padding: .5rem 2rem;
	}

	.optgroup .option {
		padding: .5rem 2rem .5rem 3rem;
	}
	
	.option, [data-disabled], [data-disabled] [data-selectable].option {
		cursor: inherit;
		opacity: 0.5;
	}
	
	[data-selectable].option {
		opacity: 1;
	}
	.optgroup:first-child .optgroup-header {
		border-top: 0 none;
	}
	
	.optgroup-header {
		background: $white;
		color: #CCC; 
		cursor: default;
	}
	
	.active {
		background-color: $green; 
		color: $white;
		&.create {
			color: $white;
		}
	}
	
	.create {
		padding: .5rem 2rem;
		color: $white;
	}
	
	.form-light & {
		.option, .optgroup-header { 
			padding: .5rem 1rem; // 2rem - 1px border;
		}
		
		.optgroup .option {
			padding: .5rem 1rem .5rem 2rem;
		}
		
		.create {
			padding: .5rem 1rem;
		}
	}
}

.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;
	-webkit-overflow-scrolling: touch;
} 

.selectize-control.single .selectize-input {
	&, input { cursor: pointer; }
	&.input-active, &.input-active input { cursor: text; }

	&:after {
		content: $select_img_down; 
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 2rem;

		.form-light & {
			right: 0;
		}
	}
	&.dropdown-active:after {
		content: $select_img_up; 
	}
}

.selectize-control .selectize-input.disabled {
	color: #CCC; 
}

// plugin DropDown header
// -----------------------------------------------------------------------------

.selectize-dropdown-header {
	position: relative;
	padding: .5rem 2rem;
	border-bottom: 1px solid $stroke;
	background: $light-grey; 
	color: $dark;
	
	.form-light & {
		padding: .5rem 1rem;
	}
}

.selectize-dropdown-header-close {
	position: absolute;
	right: 1rem;
	text-decoration : none;
	top: 50%;
	color: $dark-grey;
	margin-top: -12px;
	line-height: 20px;
	font-size: 20px !important; 
	text-decoration: none !important; 
}
.selectize-dropdown-header-close:hover {
	color: $black; 
}

// plugin Remove Button
// -----------------------------------------------------------------------------

.selectize-control.plugin-remove_button {
	[data-value] {
		position: relative;
		padding-right: 1.5rem !important;
	}
	[data-value] .remove {
		z-index: 1; /* fixes ie bug (see #392) */
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 17px;
		text-align: center;
		font-weight: normal;
		font-size: 1rem;
		color: inherit;
		text-decoration: none;
		vertical-align: middle;
		display: inline-block;
		
		border-left: 1px solid #FFF;
		@include style-box-sizing(border-box);
	}
	[data-value] .remove:hover {
		background: rgba(0,0,0,0.05);
	} 
	.disabled [data-value] .remove:hover {
		background: none;
	} 
	.remove-single {
		position: absolute;
		right: 0;
		top: 0;
		text-decoration: none;

		color: $red; 
	}
}

.has-error.form-group .selectize-input {
  //  box-shadow: none !important;
}