$discount-green			: #408d1b;
$discount-red			: #B52D35;
$discount-blue			: #1C83C1;

.discount-section {
	padding: 6rem 0;
	border-top: 1px solid #f2f2f2;
	background: url('#{$asset-path}images/bg/bg-zebra.png'); 
} 

.macaroon { 
	text-align: center;
	height: 240px;
	width: 240px;
	margin: 0 auto;
	position: relative;
	border-radius:120px;
	margin-bottom: 1.5rem;
	background-color: #000;
	-webkit-box-shadow: inset 0px 0px 0px 12px rgba(0,0,0,0.1);
	-moz-box-shadow: inset 0px 0px 0px 12px rgba(0,0,0,0.1);
	box-shadow: inset 0px 0px 0px 12px rgba(0,0,0,0.1);
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.macaroon-red .macaroon {
    background-color: $discount-red;
}
.macaroon-green .macaroon {
    background-color: $discount-green;
}
.macaroon-blue .macaroon {
     background-color: $discount-blue;
}

.macaroon-title {
	border-bottom: 1px solid #fff;
	color: #fff;
	padding: .5rem;
	width: 150px;
}

.macaroon-title span {
    display: block;
    text-align: center;
}

.macaroon-title .imp {
    font-size: 3.5em;
    line-height: 0.8;
	font-weight: bold;
}
.macaroon-title .sml {
    font-size: 1.5rem;
    line-height: 0.8;
}

.macaroon-desc {
	color: #fff;
	font-size: 1rem;
	line-height: 1;
	padding: .5rem;
	width: 150px;
}

.macaroon-icon {
	bottom: 0;
	right: -40px;
	position: absolute;
	
	&.promo-skis {
		background: url('#{$asset-path}images/bg/promo-skis.png'); 
		width: 69px;
		height: 139px;
	}
}

.macaroon-legend {
	text-align: center;
	color: #8d8d8d;
	font-size: 1.8em;
	font-weight: bold;
	line-height: 1em;
}

.macaroon-green .macaroon-legend {
	color: $discount-green;
}
.macaroon-blue .macaroon-legend {
	color: $discount-blue;
}