#booking_flip {
	visibility: hidden;
}
#booking_choice_skier,
#booking_choice_equipment,
#booking_choice_catalog,
#booking_choice_misc_casse,
#booking_choice_misc_express,
#booking_choice_cadd,
#booking_choice_add {
	background: $white;	
	display: flex;
	flex-direction: column;
	
	.flip-body {
		
		.title {
			font-size: 2rem;
			font-weight: 700;
			line-height: 1.25;
			margin-bottom: .25rem; 

			span {
				color: $red;
			}
		}
	}
	.flip-action {
		background: $white;	
	}
}

#booking_choice_catalog {
	.list-material {
		margin: 2rem -2rem;
	}
}

#booking_choice_misc_casse {
	.flip-body {
		background: #4291c9 url('#{$asset-path}images/bg/ll.jpg') no-repeat top center;
		background-size: cover;
		
		display:flex;
		flex-direction: column;
		
		align-items: center;
	}
	
	.flip-action {
		display:flex;
		justify-content: center;
		
		button{
			margin: 0 .5rem;
			padding: 1rem 2rem;
		}
	}
	
	.header {
		text-align: center;
		margin-bottom: 1rem;
		span {
			display: block;
			color: $white;
			font-size: 1rem;
			font-weight: 400;
			font-style: oblique;
			
			&:last-child {
				text-transform: uppercase;
				font-size: 1.4rem;
				font-weight: 600;
				font-style: normal;
			}
		}
	}
	
	.bull {
		background: rgba($white, .9);
		border-radius: 2rem;
		padding: 2rem;
		margin: 1rem 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		box-shadow: $shadow_100;
		
		ul {
			font-size: 1.4rem;
			font-weight: 600;
		}
		
		p {
			text-align: center;
		}
		
		span {
			&.price {
				font-size: 5rem;
				font-weight: 700;
				color: $green;
				line-height: 1;
				font-style: oblique;
			}
			
			&.price-from,
			&.price-unit {
				font-weight: 700;
			}
		}
	}
}

#booking_choice_misc_express {
	.radchk-group {
		margin: 2rem 0;
		
		.subtitle {
			margin: 2rem 2rem 1rem;
			font-size: 1.2rem !important;
			font-weight: 600;
			line-height: 1;
		}
		
		.radio {
			
			label {
				font-size: 1.2rem;
				font-weight: 600;
				line-height: 1;
			}
			
			.control-help {
				font-size: 1rem;
				padding: 0 0 0 2rem;
			}
			
			+ .radio  {
				margin-top: 2rem;
			}
		}
	}
}

.skier-count {
	border: 1px solid $stroke;
	border-radius: 2rem;
	display: inline-flex;
	align-items: center;
	padding: .5rem 1rem;
	font-weight: 700;
	margin-bottom: 1rem;
	
	&:before {
		content: "\e929";
		font-family: "skiset";
		margin-right: .5rem;
		color: $dark-grey;
		font-weight: normal;
		font-size: 1.125rem;
	}
}

$equips: 'skis', 'snowboard', 'snowblade', 'nordiq', 'shoes';

.equipment-select {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 2rem 0;
	
	> div.select {
		flex: 0 1 50%;
		width: 50%;
		padding: 1rem;
		text-align: center;
		font-weight: 700;
		cursor: pointer;
		outline: none;
		
		&:nth-child(even) {
			padding-right: 0;
		}
		&:nth-child(odd) {
			padding-left: 0;
		}
		
		span {
			border-radius: 2rem;
			border: 1px solid $stroke;
			display: flex;
			height: 160px;
			justify-content: center;
			align-items: center;
			margin-bottom: .5rem;
			background-size: 50% 50%;
			background-repeat: no-repeat;
			background-position: center center;
			
			@each $equip in $equips {
				&.#{$equip} {
					background-image:  url('#{$asset-path}svg/equipment/#{$equip}.svg');
				}
			}
		}
		
		&.current span, &:hover span {
			border-color: $green;
			
			@each $equip in $equips {
				&.#{$equip} {
					background-image:  url('#{$asset-path}svg/equipment/#{$equip}-hover.svg');
				}
			}
		}
	}
}