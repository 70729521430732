.proprio-header {
	min-height: 500px;
	background-image: url('#{$asset-path}images/bg/bg-proprio.jpg');
	background-repeat: no-repeat;
	background-size: cover;
} 

.proprio-cards {
	display: flex;
	margin: 4rem 0; 
}

.proprio-card {
	flex:1;
	margin: 1rem; 
	padding: 2rem;
	background: $light-grey;
	border-radius: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: transform .5s ease;
	
	&:hover {
		color: #FF5C40;
		transform: translateY(-20px);
	}
	
	img {
		margin: 1rem auto;
	}
	
	h3 {
		margin: 1.5rem 0;
		text-transform: uppercase;
	}
	
	p {
		text-align:center;
		color: $dark-grey;
	}
} 

.proprio-actions {
	margin: 4rem auto 10rem auto;
	text-align: center;
}



@include media-breakpoint-down('lg') {
	.proprio-header {
		background: none;
		display: none;
	} 
	.proprio-cards {
		flex-direction: column;
	}
	
	.proprio-card, .proprio-card:hover  {
		color: $black;
		transform: none;
	}
}