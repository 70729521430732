.ident-wrapper { 
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	background: #EBECF1 url('../../assets/images/bg/login/bg.jpg') no-repeat scroll top left;
	background-size : cover;
}

.ident-tab {
	display:none;
	position: relative;
	
	&.active {
		display: block;
	}
	
	.link {
		position: absolute;
		right: .5rem;
		top: .5rem;
		
		.link-icon {
			width: 2.625rem;
			height: 2.625rem;
			background: $green;
			border: none;
			color: $white !important;
		}
	}
}