.maintenance-wrapper { 
	position: relative;
	display:flex;
	justify-content: center;
	
	> div {
		padding: 4rem;
	}
}

.maintenance {
	margin:.5rem 0;
	display:flex;
	align-items: center;
	flex-wrap: wrap;
	
	img {
		width: 25px;
		margin-right: .5rem;
	}
	
	span {
		display:inline-block;
		margin-right: .5rem;
		 
		&:last-child {
			color: $dark-grey;
			font-style: oblique;
		}
	}
}