@mixin expandNav() {
	
	.navbar-toggler {
		display: none;
	} 
	
	.dropdown-menu {
		display: block;
		position: static !important;
		float: none;
		transform: none !important;
		box-shadow: none !important;
		border:none;
		margin-top: 0;

		.dropdown-item {
			border-bottom: 1px solid $stroke;
			background: none;

			&:hover, &:active {
				color: $dark;
			}

			&.active, &.active:hover, &.active:active {
				color: $red;
			}
		}
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {	
		.nav-toggler.nav-expand-#{$breakpoint} {
			 @include expandNav();
		}
	}
}