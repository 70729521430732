footer {
	.no-footer & {
		display: none;
	}
}

.footer-explain {
	
	.container {
		padding-top: 4rem;
		padding-bottom: 4rem;
		
	}
	
	.title {  
		color: $gray-700;
		display: block;
		font-weight: bold; 
		text-align: center;
		text-transform: uppercase; 
		margin-bottom: 1.5rem; 
	}
	
	.links {
		a {
			position: relative;
			padding-right: 1rem;
			margin-right: .5rem;
			display: inline-block;
			&:after { 
				content: "-"; 
				position: absolute;
				right: 0;
			}
			&:last-child:after{ content: none;}
		}
	}
	
	.col {margin-bottom: 2rem;}
	p { color: $text-muted; }
	a { color: $gray-800;}
}

/* Footer Global
---------------------------------- */

.footer-global { 
	border-top: 5px solid #3490bb;
	background: #3490bb url('#{$asset-path}images/bg/foot.jpg') no-repeat scroll 0 0;
	background-size: cover;
	
	* {
		color: $white;
	}
	
	a {
		text-decoration: none;

		&:hover {
			color: rgba($white, .8);
		}
	}
	
	.container {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	
	.cont-links {
		display: flex;
		flex-wrap: wrap;
		
		> div {
			flex: 1 0;
			margin-bottom: 2rem;
		}
		
		span.title {
			display: block; 
			text-transform: uppercase;
			font-weight: 700;
			font-size: .875rem;
		}
	
		.links {

			@include  media-breakpoint-down('lg') {
				flex: 0 0 50%;
			}
			@include  media-breakpoint-down('sm') {
				flex: 0 0 100%;
			}

			> ul {
				margin: 1rem 1rem 0 0;
				list-style-type: none;
				padding: 0;

				li {
					margin: 0 ;
					padding: 0;

					a {
						font-weight: 300;
						font-size: 1rem;
						display:block;
						padding: .25rem 0;
					}
				}
			}
		}

		.list-in {
			background: rgba($white,.05);
			padding: 2rem;
		}
		
		.call ul {
			list-style: outside none none;
			padding: 0;
			margin:0;
			
			li {
				&.call-title {
					font-size: 1rem;
				}
				&.call-number {
					font-size: 1.5rem;
				}
				&.call-info {
					font-size: .875rem;
				}
			}
		}
	}
	
   
	.socials {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		
		@include media-breakpoint-down('md') {
			justify-content: center;
		}

		ul  {
			display: flex;
			margin: 0;
			padding:0;
			list-style: none;

			li {

				+ li {
					margin-left: .5rem;
				}

				a {
					text-decoration: none;
					span {
						color: $gray-900;
						background: #FFF;
						font-size: 1.4rem;
						display: flex;
						width: 2.5rem; height: 2.5rem;
						border-radius: 50%;
						justify-content: center;
						align-items: center;

						&.icon-sk-facebook:hover {
							background: $facebook;
							color: #FFF;
						}
						&.icon-sk-instagram:hover {
							background: $instagram;
							color: #FFF;
						}
						&.icon-sk-twitter:hover {
							background: $twitter;
							color: #FFF;
						}
					}
				}
			}
		}
	}
	
	.footer-bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;

		* {
			font-size: .875rem;
		}
	}
}  

/* Payments
---------------------------------- */

.footer-payment {
	background:$white;
	padding: 2rem 1.5rem; 
	display: flex; 
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	
	img {
		height: 24px;
		margin: 1rem;
	}
}