.banner-checkyeti {
	margin: 8rem 0;
	background-color: #2eb1ff;
	border-radius: 2rem;
	display: flex;
	overflow: hidden;
	 
	
	.illustration {
		order: 2;
		flex-basis: 50%;
		width: 50%;
		position:relative;
		
		background-image: url('#{$asset-path}images/banners/ski_lessons/checkyeti-bg.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		
		&:before {
			content: '';
			position:absolute;
			bottom: 1rem;
			left: 1rem;
			width: 200px;
			height: 156px;
			background-image: url('#{$asset-path}images/banners/ski_lessons/cy.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
	
	.content {
		flex-basis: 50%;
		width: 50%;
		padding: 3rem;
		color: $white;
	}
	
	.title {
		font-size: 1.6rem;
		font-weight: 600;
		line-height: 1.2;
	}
	
	.sub-title {
		font-size: 1.2rem;
		font-weight: 500;
		margin-bottom: 1.5rem;
		line-height: 1.2;
	}
	
	ul.checkmark li::before {
		color: $white;
	}
	
	.lnk {
		text-align: center;
		margin-top: 2rem;
	}
}

.banner-generic {
	margin: 8rem 0;
	border-radius: 2rem;
	height: 400px;
    background-image: url('#{$asset-path}images/banners/ski_lessons/checkyeti-sm.jpg');
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center; 
	overflow: hidden;
	
	> div {
		padding:2rem;
		width: 100%;
		background: rgba(21,110,152,.5);
		text-align: center;
	}
	
	p {
		color: #fff;
		font-weight: 700;
		font-size: 2.4rem;
		text-shadow: 1px 1px 1px rgba(0,0,0,.2);
	}
}  

@include media-breakpoint-down('lg') {
	.banner-checkyeti {
		flex-direction: column;
		
		.illustration,
		.content { 
			flex-basis: auto;
			width: 100%;
		}
		
		.illustration {
			background-image: url('#{$asset-path}images/banners/ski_lessons/checkyeti-sm.jpg');
			height: 300px;
		}
	}
	
	.banner-generic {
		p {
			font-size: 1.5rem;
		}
	}
}