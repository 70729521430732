/* Google
--------------------------------*/
/* poppins-100 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-100.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-100italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-100italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-100italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-100italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-200 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-200.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-200.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-200italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-200italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-200italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-300 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-300italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-500.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-500italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-500italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-600italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-600italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-800 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-800.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-800.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-800italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-800italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-800italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-800italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-900 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-900.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-900italic - latin */
@font-face {
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url('#{$font-path}poppins/poppins-v15-latin-900italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('#{$font-path}poppins/poppins-v15-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}poppins/poppins-v15-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-900italic.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}poppins/poppins-v15-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}poppins/poppins-v15-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* Icons 
--------------------------------*/

@font-face {
	font-family: 'skiset';
	src:  url('#{$font-path}skiset.eot?4n86rn');
	src:  url('#{$font-path}skiset.eot?4n86rn#iefix') format('embedded-opentype'),
	  url('#{$font-path}skiset.woff2?4n86rn') format('woff2'),
	  url('#{$font-path}skiset.woff?4n86rn') format('woff'),
	  url('#{$font-path}skiset.ttf?4n86rn') format('truetype'),
	  url('#{$font-path}skiset.svg?4n86rn#skiset') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
@font-face {
	font-family: 'sk-measure';
	src: url('#{$font-path}sk-measure.eot?uxido4');
	src: url('#{$font-path}sk-measure.eot?uxido4#iefix') format('embedded-opentype'),
		url('#{$font-path}sk-measure.woff2?uxido4') format('woff2'),
		url('#{$font-path}sk-measure.woff?uxido4') format('woff'),
		url('#{$font-path}sk-measure.ttf?uxido4') format('truetype'),
		url('#{$font-path}sk-measure.svg?uxido4#sk-measure') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
@font-face {
	font-family: 'shopservice';
	src:  url('#{$font-path}shopservice.eot?samqqx');
	src:  url('#{$font-path}shopservice.eot?samqqx#iefix') format('embedded-opentype'),
		url('#{$font-path}shopservice.woff2?samqqx') format('woff2'),
		url('#{$font-path}shopservice.woff?samqqx') format('woff'),
		url('#{$font-path}shopservice.ttf?samqqx') format('truetype'),
		url('#{$font-path}shopservice.svg?samqqx#shopservice') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-sk-"], [class*=" icon-sk-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'skiset' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-sk-qrcode:before {
	content: "\e92a";
}
.icon-sk-document:before {
	content: "\e927";
}
.icon-sk-card:before {
	content: "\e928";
}
.icon-sk-skiset:before {
	content: "\e922";
}
.icon-sk-info:before {
	content: "\e921";
}
.icon-sk-logout:before {
	content: "\e920";
}
.icon-sk-phone:before {
	content: "\e90c";
}
.icon-sk-contact:before {
	content: "\e90c";
}
.icon-sk-info-round:before {
	content: "\ea0c";
}
.icon-sk-pano:before {
	content: "\ea2e";
}
.icon-sk-share:before {
	content: "\e926";
}
.icon-sk-social:before {
	content: "\e926";
}
.icon-sk-search:before {
	content: "\e913";
}
.icon-sk-language:before {
	content: "\e90b";
}
.icon-sk-cart:before {
	content: "\e94c";
}
.icon-sk-facebook:before {
	content: "\ea90";
}
.icon-sk-twitter:before {
	content: "\ea96";
}
.icon-sk-instagram:before {
	content: "\e90d";
}
.icon-sk-checkmark:before {
	content: "\ea10";
}
.icon-sk-bubble:before {
	content: "\e916";
}
.icon-sk-mobile:before {
	content: "\e91d";
}
.icon-sk-mail:before {
	content: "\e91e";
}
.icon-sk-calendar:before {
	content: "\e91f";
}
.icon-sk-man:before {
	content: "\e923";
}
.icon-sk-woman:before {
	content: "\e924";
}
.icon-sk-dots-vertical:before {
	content: "\e925";
}
.icon-sk-skier:before {
	content: "\e917";
}
.icon-sk-ski:before {
	content: "\e918";
}
.icon-sk-ski2:before {
	content: "\e929";
}
.icon-sk-trail:before {
	content: "\e919";
}
.icon-sk-lift:before {
	content: "\e91a";
}
.icon-sk-alt:before {
	content: "\e91b";
}
.icon-sk-slope:before {
	content: "\e91c";
}
.icon-sk-add:before {
	content: "\e915";
}
.icon-sk-edit:before {
	content: "\e906";
}
.icon-sk-close:before {
	content: "\e907";
}
.icon-sk-cancel:before {
	content: "\e907";
}
.icon-sk-map:before {
	content: "\e90f";
}
.icon-sk-mounts:before {
	content: "\e904";
}
.icon-sk-home:before {
	content: "\e905";
}
.icon-sk-storefront:before {
	content: "\e90a";
}
.icon-sk-basket:before {
	content: "\e908";
}
.icon-sk-account:before {
	content: "\e909";
}
.icon-sk-back:before {
	content: "\e90e";
}
.icon-sk-chevron-right:before {
	content: "\e900";
}
.icon-sk-chevron-left:before {
	content: "\e901";
}
.icon-sk-chevron-top:before {
	content: "\e902";
}
.icon-sk-chevron-bottom:before {
	content: "\e903";
}
.icon-sk-delete:before {
	content: "\e914";
}
.icon-sk-unselect:before {
	content: "\e910";
}
.icon-sk-select:before {
	content: "\e911";
}
.icon-sk-list1:before {
	content: "\e912";
}

[class^="icon-ms-"], [class*=" icon-ms-"], .icon-ms {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'sk-measure' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-ms-calfwidth:before {
  content: "\e900";
}
.icon-ms-footwidth:before {
  content: "\e901";
}
.icon-ms-headsize:before {
  content: "\e902";
}
.icon-ms-height:before {
  content: "\e903";
}
.icon-ms-shoesize:before {
  content: "\e904";
}
.icon-ms-weight:before {
  content: "\e905";
}

[class^="icon-sv-"], [class*=" icon-sv-"], .icon-sv {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'shopservice' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Services */
// ---------------------------------------

.icon-sv-airbag:before {
  content: "\e911";
}
.icon-sv-arva:before {
  content: "\e910";
}
.icon-sv-bootfitting:before {
  content: "\e909";
}
.icon-sv-cam:before {
  content: "\e90c";
}
.icon-sv-clothing:before {
  content: "\e90b";
}
.icon-sv-delivery:before {
  content: "\e90a";
}
.icon-sv-express:before {
  content: "\e94d";
}
.icon-sv-fatbike:before {
  content: "\e905";
}
.icon-sv-lockers:before {
  content: "\e908";
}
.icon-sv-repair:before {
  content: "\e901";
}
.icon-sv-safekeeping:before {
  content: "\e903";
}
.icon-sv-scanner3d:before {
  content: "\e900";
}
.icon-sv-shoedrying:before {
  content: "\e904";
}
.icon-sv-shop:before {
  content: "\e902";
}
.icon-sv-skipass:before {
  content: "\e912";
}
.icon-sv-skischool:before {
  content: "\e913";
}
.icon-sv-sledge:before {
  content: "\e90f";
}
.icon-sv-snowscoot:before {
  content: "\e907";
}
.icon-sv-snowshoes:before {
  content: "\e90d";
}
.icon-sv-telemark:before {
  content: "\e90e";
}
.icon-sv-transport:before {
  content: "\e906";
}
.icon-sv-wifi:before {
  content: "\e91b";
}
