// navbar

.navbar-toggler {
	padding: 0;
}

// badge

.badge {
	border-radius: .25rem;
}

// popover

.popover-header {
    padding: .5rem 1rem;
    background-color: $light-grey;
    border: none;
}

.popover-body {
	padding: 1rem;
}


// dropdown

.caret-off::before {
	display: none;
}
.caret-off::after {
	display: none;
}

.dropdown {
	position:relative;
	
	.hamb {
		position: relative;
		width: 30px; 
		height: 24px;

		span  {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			background: $black;				
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;

			&:nth-of-type(1) { 
				top: 0px; 
				left: 0;
				width: 100%;
			}

			&:nth-of-type(2), &:nth-of-type(3) { 
				top: 10px;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			&:nth-of-type(4) { 
				top: 20px; 
				left: 0;
				width: 100%;
			}
		}
	}
	
	.dropdown-menu {
		background-color: $white;
		box-shadow: $shadow_100; 
		border-radius: 0; 
		float: none;
		padding:0;
		margin-top:.5rem;
		z-index: 900;
		
		.dropdown-item {
			padding:1rem;
		}
	} 
	
	&.show {
		
		.hamb span {
			&:nth-of-type(1), &:nth-of-type(4) { 
				top: 10px;  
				width: 0%;
				left: 50%;
			}
			&:nth-of-type(2) { 
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			&:nth-of-type(3) {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}
		}
	}
}

// grid

.row.small-gutter {
	margin-right: -1rem;
	margin-left: -1rem;
	
	> .col, [class^="col-"] {
		padding-left : 1rem;
		padding-right : 1rem;
	}
}

// nav 

ul.nav {
	
	&.menu-nav {
		display:flex;
		flex-direction: column;
		
		.nav-item {
			border-bottom: 1px solid $stroke;
		}
		.nav-link {
			padding: 1rem;
		}
	} 
	.nav-item {
		
		
		.nav-link {
			color: $black;
			
			&.active, &:hover {
				color: $red;
			}
		}
	}
}

// card

.card {
	position:relative;
	background-color: $white;
	user-select: none;
	border-radius: .5rem;
	box-shadow: $shadow_100;
	margin-bottom: 2rem;
	padding: 0;
	overflow: hidden;
	
	.card-header,
	.card-body,
	.card-footer{
		padding: 1.5rem;
	}
}

// modal

.modal-dialog{
	margin-top: 5rem;
}

.modal-content {
	border-radius:2rem;
	border:none;
	position:relative; 
	.close {
		position: absolute;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 1.25rem;
		top: -1.25rem;
		right: 2rem;
		padding:0;
		margin: 0;
		background: $light-grey;
		font-size: 1rem;
		font-weight: normal;
		opacity: 1 !important;
		z-index: 10;
	}
	.modal-body {
		padding: 2rem;
	}
	 
	.title {
		font-size: 1.2rem;
		font-weight: 600;
		margin-bottom: 1rem;
		
		span {
			color: $red;
		}
	}
	
	.btn {
		padding: 1rem 2rem;
	}
}

@include media-breakpoint-down('md') {
	.modal-dialog{
		margin-top: 2rem;
	}
	.modal-content .modal-body {
		padding: 1.5rem;
	}
}
