.form-validation {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	
	&.validation-left {
		justify-content: flex-start;
	}
	
	&.validation-right {
		justify-content: flex-end;
	}
	
	.btn {
		display: inline-flex;
		align-items: center;
		margin-bottom: 1rem;
		
		 + .btn {
			margin-left: 1rem;
		}
	}
}

.form-swap {
	display:flex;
	background: $light-grey;
	border-radius: 3rem;
	justify-content:space-between;
	align-items: center;
	padding: .5rem;
	
	a {
		flex: 1;
		padding: .5rem 1.5rem;
		font-weight: 600;
		display:flex;		
		justify-content: center;
		align-items: center;
		color: $dark-grey;
		text-decoration: none;
		
		&:before {
			font-family: "skiset";
			font-size: 1rem;
			margin-right: .5rem;
			color: $dark-grey;
		}
		
		&.list:before {
			content: "\e912";
		}
		&.map:before {
			content: "\e94c";
		}
		
		&.current {
			background: $white;
			box-shadow: $shadow_100;
			border-radius: 3rem;
			color: $black;
			cursor: default !important;
			
			&:before {
				color: $black;
			}
		}
	}
}