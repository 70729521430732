.homebox-wrapper {
	display: none;
	position:absolute; 
	width: calc(100% - 2rem);  height: 100%;
	left: 0; right: 2rem; top: 0; bottom:0;
	z-index: 100;
	
	&.show, &.loading {
		display: flex;
	}
	
	&.loading {
		&:before {
			content: '';
			position:absolute;
			left: 0; right: 0; top: 0; bottom:0;
			background: $white;
			-webkit-animation: fadeIn .5s ease both;
			animation: fadeIn .5s ease both;
			opacity: 0;
		}
	} 
}

.homebox {
	flex:1;
	background: $white;
	display: flex;
	flex-direction: column;
	border-radius:.3rem;
	box-shadow: 0 4px 5px -2px rgba(0,0,0,.2);
	padding:2rem;
}

.homebox-header {
	position: relative;
	margin-bottom: 2rem;
	display:flex;
	justify-content: space-between;
	align-items: center;
	
	.title {
		font-size:1rem;
		font-weight: 600;
		line-height: 1.2;
		
		a {
			display:block;
		}
	}
}
	
.homebox-content {
	flex:1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	
	> div {
		display: none;
		
		&.current {
			display: flex;
		}
	}
}

.homebox-btn-map {
	background:$white;
	outline: none;
	border:none;
	box-shadow: $shadow_100;
	font-weight: 500;
	color: $dark-grey;
	display:flex;		
	justify-content: center;
	align-items: center;
	border-radius: 3rem;
	padding: 1rem 4.5rem 1rem 1.5rem;
	position: relative;
	margin-top: -4rem;
	
	&:after {
		content: "\e90f";
		font-family: "skiset";
		font-size: 1rem;
		top:.25rem;
		bottom:.25rem;
		right: .25rem;
		width: 3rem;
		height: 3rem;
		position: absolute;
		color: $white;
		background: $green;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 1.5rem;
	}
}

.homebox-map {
	background: $white url('#{$asset-path}images/bg/map.png') no-repeat scroll 0 0;
	background-size: cover;
	flex:1;
	display: flex;
	justify-content: center;
	align-items: center;
	
	.btn {
		-background: $white;
		margin-top: -3rem;
		box-shadow: 0 1px 6px rgba(0,0,0,.15),0 2px 4px -2px rgba(24,39,75,.12),0 4px 4px -2px rgba(24,39,75,.08);
	}
}

.homebox-shops {
	flex:1;
	overflow: auto;
	flex-direction: column;
}
	
.homebox-shop {
	margin: 1rem 0;
	display: flex;
	align-items: flex-start;
	
	.illustr {
		width: 120px;
		height: 120px;
		flex-basis: 120px;
		flex-shrink: 0;
		background-size: cover;
	}
	
	.sk-card  {
		margin-left: 1.5rem;
	}
}