$vFade:						.1s;
$vMoveTo:					.5s;
$vMoveFrom:					.5s; 

.flip {
	position: relative;
	visibility: visible !important;
	height: 100%;
	width: 100%;
	overflow: hidden;
	 
	> div {
		position: absolute;
		left: 0; right:0; top:0; bottom:0;
		width: 100%;
		height: 100%;
		display: none !important;
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden;		
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		
		&.current {
			display: flex !important;
			flex-direction: column;
			z-index: 99;
		}
		
		&.fadeOut {
			-webkit-animation: fadeOut $vFade ease both;
			animation: fadeOut $vFade ease both;
			opacity: 1;
			z-index: 100;
		}
		
		&.fadeIn {
			-webkit-animation: fadeIn $vFade ease both;
			animation: fadeIn $vFade ease both;
			opacity: 0;
			z-index: 100;
		}
		
		&.moveToLeft {
			-webkit-animation: moveToLeft $vMoveTo ease both;
			animation: moveToLeft $vMoveTo ease both;
			z-index: 90;
		}
		
		&.moveFromLeft {
			-webkit-animation: moveFromLeft $vMoveTo ease both;
			animation: moveFromLeft $vMoveTo ease both;
			z-index: 90;
		}
		
		&.moveToRight {
			-webkit-animation: moveToRight $vMoveTo ease both;
			animation: moveToRight $vMoveTo ease both;
			z-index: 90;
		}

		&.moveFromRight {
			-webkit-animation: moveFromRight $vMoveTo ease both;
			animation: moveFromRight $vMoveTo ease both;
			z-index: 90;
		}
		
		&.moveToLeft:before, &.moveToRight:before {
			content: '';
			background: rgba(0,0,0,.2);
			position:absolute;
			left:0;right:0; top:0;bottom:0;
			width:100%; height:100%;
			z-index: 90;
		}
		
		&.shadowLeft {
			@include style-box-shadow(10px 0px 10px 0px rgba(0,0,0,0.1) inset);
		}
		 
		&.shadowRight {
			@include style-box-shadow(-10px 0px 10px 0px rgba(0,0,0,0.1) inset);
		}
	}
}

.flip-header {
	display:flex;
	justify-content:space-between;
	flex-wrap: wrap;
	padding: 1.5rem 2rem 0;
	position:relative;
	min-height: 84px; 
	
	a {
		text-decoration: none !important;
		color: $black;
		display: flex;
		
		span {
			font-size: 1.5rem;
			line-height: 40px;
			
			&.logo {
				height: 40px;
				width:40px;
				background: transparent url('#{$asset-path}logo/skiset-square.svg') top left no-repeat;
				display: inline-block;
				margin-left: 1rem;
			}
		}
	}
	
	.flip-header-step {
		width: 100%;
		flex: 1 0 100%;
		height:  4px;
		background: $light-grey;
		border-radius: 2px;
		position:relative;
		margin-top: 1rem;
		
		&:before {
			content: '';
			position: absolute;
			height: 4px;
			width: 0px;
			background: $primary;
			border-radius: 3px;
		}
		
		@for $i from 1 through 10 {
			$res:  10 * $i;
			&.step-#{$res}:before {
				width: 1% * $res; 
			}
		}
	}
}

.flip-body {
	padding: 2rem;
	overflow: auto;
	flex: 1 1 auto;	
}
.flip-action {
	
	padding: 1.5rem 2rem ;
}

@-webkit-keyframes moveToLeft{
	0%{
		@include style-transform(translate3d(0,0,0));
	}
	to{
		@include style-transform(translateX(-100%));
	}
}
@keyframes moveToLeft{
	0%{
		@include style-transform(translate3d(0,0,0));
	}to{
		@include style-transform(translateX(-100%));
	}
}

@-webkit-keyframes moveFromLeft{
	0%{
		@include style-transform(translateX(-100%));
	}
	to{
		@include style-transform(translate3d(0,0,0));
	}
}
@keyframes moveFromLeft{
	0%{
		@include style-transform(translateX(-100%));
	}to{
		@include style-transform(translate3d(0,0,0));
	}
} 

@-webkit-keyframes moveToRight{
	0%{
		@include style-transform(translate3d(0,0,0));
	}
	to{
		@include style-transform(translateX(100%));
	}
}
@keyframes moveToRight{
	0%{
		@include style-transform(translate3d(0,0,0));
	}to{
		@include style-transform(translateX(100%));
	}
} 

@-webkit-keyframes moveFromRight{
	0%{
		@include style-transform(translateX(100%));
	}
	to{
		@include style-transform(translate3d(0,0,0));
	}
}
@keyframes moveFromRight{
	0%{
		@include style-transform(translateX(100%));
	}to{
		@include style-transform(translate3d(0,0,0));
	}
}  

@-webkit-keyframes fadeIn{
	0%{
		opacity: 0;
	}to{
		opacity:1;
	}
}
@keyframes fadeIn {
	0%{
		opacity: 0;
	}to{
		opacity:1;
	}
} 

@-webkit-keyframes fadeOut{
	0%{
		opacity: 1;
	}to{
		opacity:0;
	}
}
@keyframes fadeOut {
	0%{
		opacity: 1;
	}to{
		opacity:0;
	}
} 