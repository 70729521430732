.modal-pack {
	.pack-content {
		display: flex;
	}

	.pack-config {
		background: $light-grey;
		padding: 2rem;
		flex: 1;
		border-radius: 2rem 0 0 2rem;
	}

	.pack-stay {
		position: relative;
		margin-bottom: 2rem;

		.duration {
			font-weight: 500;

			span {
				color: $red;
			}
		}

		.date {
			font-size: .875rem;
			color: $dark-grey;

			span {
				color: $dark;
			}
		}

		.link {
			position: absolute;
			top:0;
			right: 0;
		}
	}

	.pack-info {
		background: $white;
		padding: 2rem;
		flex:2;
		border-radius: 0 2rem 2rem 0;
		display: flex;
		position:relative;

		&.ultimate-info::after {
			content:'';
			position: absolute;
			z-index:8;
			background: #FFF url('#{$asset-path}images/bg/ultimate2.jpg') no-repeat center center;
			background-size: cover;
			left:0;
			right:0;
			top:0;
			bottom: 0;
			border-radius: inherit;
		}
	}

	.pack-info-tab {
		display:none;

		&.active {
			display: flex !important;
			flex-direction: column;
			flex: 1;
			justify-content: center;
		}
	}

	.pack-info-content {
		flex: 1;
		margin:1rem 0;
	}

	.pack-profil {
		a {
			color: $dark;
			display: flex;
			align-items: center;
			text-decoration: none !important;

			span {
				font-size: 1rem;
				color: $stroke;
				margin-right: .25rem;
			}
		}
	}

	.group-shoes-ultimate {

		border: 1px solid $stroke;
		background: #FFF;
		margin: 1rem -1rem -1rem;
		border-radius: 12px;

		.option {
			padding: .5rem 1rem;
			margin: 0;
		}

		.option-ultimateshoes {
			background: #1a1a1a;
			position:relative;
			border-radius: 0 0 12px 12px;

			.helper {
				margin-right: .5rem;
			}

			label {
				flex-wrap: wrap;
				color: #FFF;

				span, span > span.info {
					font-size: 12px;
					display: initial;
					color: #FFF;
				}
			}

			.infos {
				margin-top: .5rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				//padding-bottom: .25rem;

				span {
					color: #FFF;
					font-size: 10px;

					&:nth-child(1) {
						font-size: 12px;
						font-weight: 500;
					}
					&:nth-child(3) {
						font-weight: 300;
					}
				}
			}

			&:hover::before {
				content: "";
				width: 20px;
				height: 20px;
				position: absolute;
				top: 50%;
				right: -10px;
				background: #1a1a1a;
				transform: translateY(-10px) rotate(45deg) ;
				z-index: 0;
			}
		}
	}

	@include media-breakpoint-down('md') {
		.pack-content {
			flex-direction: column;
		}

		.pack-config {
			border-radius: 2rem 2rem 0 0;
		}

		.pack-info {
			border-radius: 0 0 2rem 2rem;
		}

		.pack-stay, .offer-composition, #pack_profil_ident, .pack-profil, #show_add, #pack_skier_add, .pack-info-content p {
			display: none !important;
		}

		.pack-info-content, fieldset {
			margin: 0;
		}
	}
}

.modal-pack-mobile {

	.pack-stay {
		position: relative;
		margin: 2rem 0;
		padding: 1rem 0;
		border-top: 1px solid lighten($stroke, 10);
		border-bottom: 1px solid lighten($stroke, 10);

		.duration {
			font-weight: 500;

			span {
				color: $red;
			}
		}

		.date {
			font-size: .875rem;
			color: $dark-grey;

			span {
				color: $dark;
			}
		}

		.link {
			text-align: center;
			margin-top: 1rem;
		}
	}

	.pack-info-tab {
		display:none;

		&.active {
			display: flex !important;
			flex-direction: column;
			flex: 1;
			justify-content: center;
		}
	}
}

.modal-selection {

	.product-title {
		font-weight: normal;
		line-height: 2rem;
		font-style: oblique;
		text-align: center;

		.name {
			font-size: 1.5rem;
		}

		.choice {
			font-size: .875rem;
			color: $dark-grey;

			&.garanty {
				color: $red;
			}
		}
	}

	.product-material {
		text-align:center;
		padding: 0;
		margin: 1.5rem 0;

		img {
			max-width: 100%;
			height: auto;
			max-height: 200px;
		}
	}

	.product-level {
		position:relative;
		width : 100%;
		height: 20px;
		border: 1px solid $stroke;
		margin: 0;
		padding: 0;
		margin-bottom: 2rem;

		> div.level {
			position: absolute;
			left: 0;
			top: 0;
			bottom:0;
			width: 0;
			background: $twitter;
			height: 18px;
		}

		span {
			position:absolute;
			font-size: .625rem; color: $stroke-hover;
			bottom:-1rem;

			&:first-child {
				left:0;
			}
			&:last-child {
				right: 0;
			}
		}
	}

	.slop {
		display: flex;
		justify-content: space-between;
		font-size: .875rem;
		span {
			font-weight: 500;
		}

		.slop-illust {
			position:relative; padding: 0; margin: 0; width : 140px; height: 26px;

			&.slop-1-11 { background: url('#{$asset-path}images/bg/slop1_11.png') top left no-repeat;}
			&.slop-1-12 { background: url('#{$asset-path}images/bg/slop1_11.png') top left no-repeat;}
			&.slop-1-13 { background: url('#{$asset-path}images/bg/slop1_13.png') top left no-repeat;}
			&.slop-1-21 { background: url('#{$asset-path}images/bg/slop1_21.png') top left no-repeat;}
			&.slop-1-31 { background: url('#{$asset-path}images/bg/slop1_31.png') top left no-repeat;}
			&.slop-2-11 { background: url('#{$asset-path}images/bg/slop2_11.png') top left no-repeat;}
			&.slop-2-12 { background: url('#{$asset-path}images/bg/slop2_11.png') top left no-repeat;}
			&.slop-2-13 { background: url('#{$asset-path}images/bg/slop2_13.png') top left no-repeat;}
			&.slop-2-21 { background: url('#{$asset-path}images/bg/slop2_21.png') top left no-repeat;}
			&.slop-2-31 { background: url('#{$asset-path}images/bg/slop2_31.png') top left no-repeat;}
			&.slop-4-41 { background: url('#{$asset-path}images/bg/slop4_41.png') top left no-repeat;}
			&.slop-6-41 { background: url('#{$asset-path}images/bg/slop6_41.png') top left no-repeat;}
		}
	}
}

.modal-video {
	.modal-body {
		display:flex;
	}

	.desc {
		color: $dark-grey;
	}
	.product {
		flex:1;
	}

	.video {
		position:relative;
		margin: 0 0 0 2rem;
		flex-basis: 0;
	}

	@include media-breakpoint-down('md') {
		.modal-body {
			flex-direction: column;
			align-items: center;
		}
		.video {
			margin: 2rem 0 0 0 ;
		}
	}
}

.modal-ultimate {
	.modal-content {
		background: #FFF url('#{$asset-path}images/bg/ultimate.jpg') no-repeat 0 0;
		background-position: 0 center;
		background-size: 232px auto;
		padding-left: 232px;
	}

	.modal-body {
		.title {
			border-left: .25rem solid $body-color;
			padding-left: .5rem;
			font-size: 1.5rem;
			span {
				font-weight: 200;
				color: $body-color;
			}
		}
	}

	@include media-breakpoint-down('lg') {
		.modal-content {
			background: #FFF;
			padding-left: 0;
		}
	}
}

.modal-stepon {
	.modal-content {
		background: #FFF url('#{$asset-path}images/bg/step-on.jpg') no-repeat 0 0;
		background-position: 0 0;
		background-size: 368px auto;
		padding-left: 368px;
	}

	.modal-body {
		.title {
			font-size: 1.5rem;
			margin-bottom: 0;
			span {
				font-weight: 200;
				color: $body-color;
			}
		}
		.subtitle {
			color: $red;
			font-size: 1.25rem;
			font-weight: 500;
			margin-bottom: 1rem;
		}
	}

	@include media-breakpoint-down('lg') {
		.modal-content {
			background: #FFF;
			padding-left: 0;
		}
	}
}

.modal-recover {
	.modal-content {
		background: #FFF url('#{$asset-path}images/bg/recover.jpg') no-repeat 0 0;
		background-size: auto 100%;
		padding-left: 40%;
	}

	.modal-body {
		padding: 3rem;
	}

	.content {
		margin-bottom: 3rem;
	}

	@include media-breakpoint-down('lg') {
		.modal-content {
			background: #FFF;
			padding-left: 0;
		}

		.heading {
			text-align:center;
		}
	}
}

.modal-casse-remind {

	.modal-content {
		overflow: hidden;
	}

	.modal-body {
		background: #4291c9 url('#{$asset-path}images/bg/ll.jpg') no-repeat top center;

		.title {
			color: $white;
			font-size: 1.5rem;
			text-align: center;
			margin-bottom: 2rem;
		}
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: center;

		> div {
			flex: 1 1 50%;
			width: 50%;
		}

		.sub-title {
			text-align: center;
			margin-bottom: 2rem;
			span {
				display: block;
				color: $white;
				font-size: 1.2rem;
				text-transform: uppercase;
				font-weight: 400;
				&:last-child {
					font-size: 1.4rem;
					font-weight: 600;
				}
			}
		}
	}

	.bull {
		background: rgba($white, .9);
		border-radius: 2rem;
		padding: 2rem;
		display: flex;
		align-items: center;
		flex-direction: column;

		margin: 0 2rem;
		box-shadow: $shadow_100;

		ul {
			font-size: 1.4rem;
			font-weight: 600;
		}

		p {
			text-align: center;

		}

		span {
			&.price {
				font-size: 7.5rem;
				font-weight: 700;
				color: $green;
				line-height: 1;
			}

			&.price-from,
			&.price-unit {
				font-weight: 700;
			}
		}

	}

	.modal-footer {
		justify-content: center;
		span {
			font-size: 1.2rem;
			font-weight: 500;
		}
	}

	@include media-breakpoint-down('md') {

		.modal-body {
			background-size: cover;
			.title {
				font-size: 1.4rem;
				margin-bottom: 1rem;
			}
		}

		.content {
			flex-direction: column;

			> div {
				flex: auto;
				width: 100%;
			}

			.sub-title {
				display: none;
			}
		}

		.bull {
			padding: 1rem;
			margin: 0 0 2rem 0;

			span.price {
				font-size: 3.5rem;
			}

			ul {
				font-size: 1.2rem;
			}

			p.small {
				display: none;
			}
		}

		.modal-footer {
			justify-content: center;

			span {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}
}