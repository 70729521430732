.context-wrapper {
	width: 100%;
	height: 700px;
	position: relative;
	background-position: center;
	background-color:$light-grey;
	background-size: cover;
	background-repeat:  no-repeat;
	background-attachment: scroll;

	overflow: hidden;
	display:flex;
	align-items: stretch;
	justify-content: center;

	.container {

		margin: auto;
		position:relative;
	}
}

.card-context {

	&:hover {
		transform: scale(1.01);
	}

	.sk-card {
		padding: 1.5rem;
	}

	.card-title {
		font-size : 1.2rem;
		font-weight: 500;
	}

	.card-content {
		flex:1;

		.icon {
			padding-left: 30px;
			position:relative;
			display:block;

			&:before {
				font-family: "skiset";
				font-size: 1rem;
				left: 0;
				position: absolute;
				color: $dark-grey;
			}

			&.shop:before {
				content: "\e90a";
			}

			&.alt:before {
				content: "\e904";
			}
		}
	}

	.card-link {
		a {
			width: 100%;
		}
	}
}

.context-resort {
	display: flex;
	justify-content: center;
}

.context-inforesort {
	display: flex;
	background: $light;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2rem 3rem;

	.infoline {
		padding: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items:center;

		.infoline-alt {

			font-size: 1.2rem;
			font-weight: 600;
			text-align: center;
			text-transform: uppercase;

			span {
				display: block;

				&.icon {
					font-size: 3rem;
					color: $stroke;
					font-weight: normal;
					text-transform: none;
					margin-bottom: .5rem;
				}
			}
		}

		.infoline-res {
			font-size: 1.2rem;
			white-space: nowrap;
			font-weight: 300;
			color:$dark-grey;

			span.slope {
				line-height:1.5rem;
				display:inline-flex;
				justify-content: center;
				align-items: center;
				width:2rem;
				height:2rem;
				margin:0 3px;
				color:#fff;
				border-radius:50%;
				font-size: .75rem;
				font-weight: 500;

				&.slope-green {
					background-color:#009c37
				}
				&.slope-blue {
					background-color:#0069ba
				}
				&.slope-red {
					background-color:#e70e1b
				}
				&.slope-black {
					background-color:#000
				}
			}
		}
	}
}

.context-mainresort {
	flex: 0 0 85%;
	padding : 4rem;
	max-width: 60%;
}

.context-description {
	p:last-child {
		margin: 0;
	}
}

.context-lokki {
    display: flex;
	border-radius: 2rem;

	.illustr {
		background-image: url('#{$asset-path}images/context/lokki.jpg') ;
		flex: 1;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 2rem 0 0 2rem;
	}

	.content {
		background: $white;
		border-radius: 0 2rem 2rem 0;
		padding: 3rem;
		flex: 0 0 40%;
		width:40%;
	}
}

.context-scanfeet {
    display: flex;
    justify-content: center;
    align-items: center;

	.content {
		margin: 0 30px 30px 0;
	}

	.title {
		font-size: 1.2rem;
		margin-bottom: 2rem;
		font-weight: 700;

		span {
			color:$red;
		}
	}
}

.context-list {
	display:flex;
	max-width: 100%;
	flex-wrap: wrap;
	justify-content: center;


	div.card-context {
		flex: 0 0 28%;
		margin: 2rem;

		@include media-breakpoint-down('lg') {
			flex: 0 0 40%;
		}
		@include media-breakpoint-down('sm') {
			flex: 0 0 80%;
		}
	}
}


.context-shop-languages {
	img {
		width: 30px;
		margin: .125rem .25 rem 0 0;
	}
}

/* Services
-------------------------------- */

.shop-services {
	display:flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: top;

}

.shop-service {
	text-align: center;
	width: 33.33%;
	flex: 0 0 33.33%;
	min-width: 120px;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	position:relative;
	margin-bottom:2rem;
	text-decoration: none !important;

	&:before {
		width: 74px;
		height: 74px;
		content:'';
		position:absolute;
		left:50%; right:0; top:12px; bottom:0;
		margin-left: -37px;
		z-index:0;
		transform: rotate(-45deg) translate3d( 0, 0, 0);
		background-color: $dark;
	}

	span {
		z-index:1;
		color: $text-muted;
		display: block;
		font-size: .875rem;

		&.leg {
			margin-top:.5rem;
		}

		&.icon-sv {
			color: #FFF;
			font-size: 2.6rem;
			line-height:2.4;
		}
	}
}

.context-media {
	display: flex;
	justify-content: center;
}

/* Peacks
-------------------------------- */

.peack {
	display: none;

	&.active {
		display: flex;
		height: 150px;
		align-items: flex-end;
		border-bottom: 1px solid $dark;
		padding-bottom: .125rem;
		width: 100%;
	}

	.hour {
		flex: 1;
		background: $dark;
		margin: 1px;

		&.peack-low {
			height: 50px; background: rgba($red, .2);
		}
		&.peack-medium {
			height: 100px; background: rgba($red, .5);
		}
		&.peack-hight {
			height: 150px; background: rgba($red, 1);
		}
	}

}

.peack-hours {
	position: relative;


	span {
		position:absolute;
		transform: translateX(-50%);
		font-size: .875rem;
		color: $text-muted;
		top: 0;

		&:nth-child(1) {left: 0;}
		&:nth-child(2) {left: 25%;}
		&:nth-child(3) {left: 50%;}
		&:nth-child(4) {left: 75%;}
		&:nth-child(5) {left: 100%;}
	}
}

/* Shops Links
------------------------------------ */

.context-shops-links {
	a {
		color: $dark-grey;
		padding: .25rem .5rem;
		position: relative;

		&:before {
			position: absolute;
			top: .5rem;
			bottom: .5rem;
			left: 0rem;
			width: 1px;
			content: '';
			background: $stroke;
		}

		&:first-child {
			&:before {
				content: none;
			}
		}
	}
}





@media (max-width: 1400px)   {
	.context-lokki {
		background-size: auto 100%;

		.content {
			flex: 0 0 50%;
			width:50%;
		}
	}

	.context-scanfeet {
		flex-direction: column
	}
}




@include media-breakpoint-down('lg') {
	.context-resort {
		flex-direction: column
	}
	.context-inforesort {
		flex-direction: row;
		justify-content: space-around;
		flex-wrap: wrap;
		padding: 2rem;

		.infoline {
			padding: 2rem;
		}
	}
	.context-mainresort {
		flex: auto;
		max-width:none;
	}

	.context-lokki {

		flex-direction: column;

		.illustr {
			border-radius: 2rem 2rem 0 0;
			height: 200px;
			min-height: 200px;
		}
		.content {
			flex: 1;
			width:100%;
			padding: 2rem;
			border-radius: 0 0 2rem 2rem ;
		}
	}

	.context-scanfeet {
		flex-direction: column
	}

}

@include media-breakpoint-down('xs') {
	.context-inforesort {
		display: none;
	}
	.context-mainresort {
		flex: 0 0 100%;
		padding : 2rem;
	}
}

