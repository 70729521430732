.owl-carousel {
	// Styling Next and Prev buttons
	.owl-nav {
		margin-top: 2rem;
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		
		&.owl-material {
			margin: 0;
			position: absolute;
			left:0; right: 0; width:100%; top:calc(50% - 3rem);
			pointer-events: none;
			z-index:10;
			
			button.owl-next,  button.owl-prev{
				position: absolute;
				pointer-events: initial;
				margin: 0;
				background-color: #283d4b;
				border: none !important;
				width: 3rem;
				height: 6rem;
				color: $white;
				opacity: .8;
				font-size:1.5rem;
				
				&:hover:not(.disabled) {
					background-color: #283d4b;
					opacity: 1;
				}
				
				&.disabled {
					opacity: .2;
				}
			}
				
			button.owl-next {
				border-radius: 1rem 0 0 1rem;
				right:0;
			}
			button.owl-prev {
				border-radius:  0 1rem 1rem 0;
				left:0;
			}
		}
		
		button.owl-next, button.owl-prev {
			transition: color .3s;
			cursor: pointer;
			background-color: $white;
			outline: none;
			border: 1px solid $stroke;
			text-decoration:none !important;
			border-radius: 1rem;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 2rem;
			height: 2rem;
			color: $black;
			transition: background .3s, border .3s;
			font-size: .875rem;
			flex-shrink: 0;
			margin: 0 0.25rem;

			&:hover:not(.disabled) {
				background-color: $light-grey;
				border-color: $stroke-hover;
			}
			
			&.disabled {
				opacity: .5;
				cursor: default;
			}
		}
	}
	
	 

	// Styling dots
	.owl-nav.disabled + .owl-dots,
	.owl-nav.owl-material + .owl-dots,
	.owl-nav.owl-material.disabled + .owl-dots{
		margin-top: 1rem;
	}

	.owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: 20px;
				height: 6px;
				margin: 2px 4px;
				background: $stroke;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: 3px;
			}
			
			&.active,
			&:hover {
				span {
					background: $green;
				}
			}
			
			&.owl-material {
				span {
					width: 16px;
					height: 16px;
					background: #283d4b;
					border-radius: 50%;
				}
				
				&.active,
				&:hover {
					span {
						background: $green;
					}
				}
			}

			
		}
	}
}


