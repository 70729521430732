.over-wrapper {
	position: fixed; height: 100%; width: 100%;
	overflow: hidden; top: 0%; left:0%; right: 0%; bottom: 0%;
	z-index: 10000;
	padding: 4%;
	background: rgba(0,0,0,0.2);
	display:none;
}

.maps-wrapper {
	position: relative;
	height: 100%; width: 100%;
	overflow: hidden;

	display:flex;
	background: $white;

	.icon-sk {
		margin-right: .5rem;
		color: $red;
	}

	.nav-link {
		padding: .5rem 0;
	}
}

.maps-panel {
	background:$light;
	box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.2);
	display:flex;
	flex-direction:column;
	position: relative;
	overflow: hidden;
	width:360px;
	z-index: 10;

	.panel-header {
		background:$black;
		height: 130px;
		position: relative;
		overflow: hidden;

		img {
			height: 130px;
		}

		div {
			position:absolute;
			width: 100%; left: 0; right: 0; bottom: 0;
			z-index: 2;
			background: rgba(0,0,0,0.7);
			padding: .25rem 1rem;

			span, a {
				color:$white;
				display:block;
			}

			a {
				text-align: right;
				font-style: oblique;
				text-decoration: none;
			}

		}
	}

	.panel-nav {
		background:$white;
		padding: 1rem;
		border-bottom:1px solid #eee;
	}

	.panel-shops {
		flex: 1;
  		overflow: auto;
		padding: 1rem;
	}

	.boardpanel-open & {
		display:none;
	}
}

.maps-content {
	flex: 1;
	display: flex;
	z-index: 5;
	position: relative;
}


/* maps containers
-----------------------------------*/

.maps-container {
	position: relative; height: 100%; width: 100%;
	background:#FFF;
	z-index: 1;

	.map-panel {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		height: 100%; width: 100%;
		padding : 0; margin: 0; display: none;

		&.active {
			display: block;
		}

		&.map-panel-gmap {
			background:#e5e3df;
		}

		&.map-panel-svg {
			padding:2rem;
		}
	}
}


/* buttons
-----------------------------------*/

.maps-buttons {
	position:absolute; z-index: 10;
	top: 10px;  right: 10px;

	.btn-group {
		display:flex;
		background: $green;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
		border-radius: .3rem;

		button {
			background: transparent;
			border:none;
			color:#FFF;
			padding: .5rem 1rem;
			outline: none;

			&:hover {
				background-color: rgba(0,0,0,.05);
			}

			+ button {
				border-left:1px solid #FFF;
			}

			.icon-sk {
				color:#FFF;
				font-size: .875rem;
			}
		}
	}
}


.maps-panel-toggle {
	position:absolute; z-index: 5;
	top: 130px;  left: 0;
	background: $green;
	border-radius: 0 .3rem .3rem 0;
	height: 5rem;
	width: 2rem;

	button {
		background: transparent;
		border: none;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
		cursor: pointer;
		height: 5rem;
		width: 2rem;
		transform: none;
		color: #FFF;
		border-radius: 0 .3rem .3rem 0;

		.boardpanel-open & {
			transform: scaleX(-1);
			border-radius: .3rem 0 0 .3rem;
		}

		&:hover {
			 background-color: rgba(0,0,0,.05);
		}
	}
}

@include media-breakpoint-down('md') {
	.over-wrapper {
		padding: 0;
	}
	.maps-panel-toggle,
	.maps-panel {
		display: none;
	}
}

// Pano info box
.info-windows-pano-container {
	box-shadow: 0 0 5px 2px rgba(0,0,0,.15);
	padding: 1rem;
	background: #FFF;
	position: relative;
	background: #fff;
	border-radius: 3px;
	max-width: 360px;

	.info-windows-pano-close {
		position: absolute;
		right: 0;
		top: 0;
		border: 0;
		border-radius: 0 3px 0 0;
		cursor: pointer;
		background-color: transparent;
		font-size: 1.2rem;
		color: #4c4c4d;
		outline: none;
		line-height: 1;

		&:hover {
			background-color: rgba(0,0,0,.05);
		}
	}
}

