header {
	position: sticky;
	top: 0px;
	z-index: 999;
	 
	.alert {
		background: #0F3C68; 
		color: #FFF;
		text-align: center;
		margin:0;
		padding: .75rem 4rem;
		
		.close {
			position: absolute;
			right: 1rem;
			font-size: 1rem;
			color: #FFF;
			opacity: 1;
			float: none;
			text-shadow: 0 1px 0 $black;
			top: 1rem;
		}
	}
	.no-header & {
		display: none;
	}
	
	&.scrolled {
		.alert {
			display: none;
		}
	}
}

.navbar {
	position:relative;
	padding: 1.5rem 2rem;
	justify-content: space-between;
	flex-wrap: nowrap;
	height: 100px;
	box-shadow: 0 0 6px 0 rgba(0,0,0,.1);
	z-index: 10;
	background: $white;
	
	&.error {
		justify-content: flex-start;
	}
	
	/* Brand
	------------------------------ */	
	
	.brand { 
		display: flex;
		justify-content:flex-start;
		align-items: center;
		
		.item {
			margin-left: 20px;
			position: relative;
			
			&:first-child {
				margin-left: 0;
			}
			
			&.logo-skiset {				
				.logo {
					display: block;
					width: 200px;
					height: 52px;
					background: transparent url('#{$asset-path}logo/skiset.svg') top right no-repeat;
				}
				
				.title {
					position: fixed;
					top: -1px;
					left: 0;
					height: 1px;
					overflow: hidden;
					display: block;
				}
			}
			.logo-partner img {
				max-width: 200px;
				max-height: 52px;
			}
		}
		
		.baseline {
			
			* {
				color: $black; 
				margin: 0; padding: 0; white-space: nowrap; font-weight: normal;
			}
			
			h1 {
				text-transform: uppercase;
				font-size: 1rem;
			}
			
			h2 {
				font-size: .75rem;
				font-style: italic; 
			}
		}
		
		&.brand-partner .baseline h1 {
			text-transform: none;
		} 
	}
	
	/* Nav
	------------------------------ */	
	
	.navbar-toggler {
		padding: .875rem .5rem;
		border-radius: 0;
		border: 0;
		background: none;
		color: $black;
		outline: none; 
		margin: 0;
		
		.hamb span {
			background: $black;
		}
	}
	
	.navbar-nav {
		flex-direction: row;
		align-items: center;

		li.nav-item {
			margin: 0 .25rem;
			position:relative;

			a.nav-link {
				position:relative;
				color: $black;
				font-size: 1.5rem;
				padding: .5rem;
				line-height: 1.5;
				border-radius: .5rem;
				
				&:hover, &:active {
					color:$dark;
					background:rgba(0,0,0,.05);
				}
			}
		}
	}
	
	.navbar-menu {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			margin: 0;
			position:relative;
				
			a {
				display: block;
				padding: 1rem;
				color: $black;
				font-size: 1rem;
				text-decoration: none;
				background:  $white;
				white-space: nowrap;
			}
			
			&.active a, a:hover{
				background:  $primary;
				color: $white;
			}
		}
	}
	
	
	.dropdown-menu {

		position: absolute;
		top: 76px; right: 0; left:auto;
		min-width: 460px;
		margin: 0;
		z-index:1000;
		border:none;
		
		&::before  {
			content: "";
			width: 20px;
			height: 20px;
			position: absolute;
			top: -10px;
			right: 10px;
			background: $white;
			transform:rotate(45deg);
			z-index: 0;
			box-shadow: -2px -2px 6px rgba(0,0,0,.1);
		} 
		
		.content {
			background: $white;
			position:relative;
			z-index: 10;
		}
			
		.title { 
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 2;
			padding: .5rem 1.5rem; 
			display: block;
			text-align: center; 
		} 
		
		&.menu-call {
			
			div.call {
				background: $light-grey;
				padding: 1rem;
				display:flex;
				flex-direction: column;
				align-items: center;
				
				* {
					margin : 0.5rem 0;
					line-height: initial;
				}
			}
		}

		&.menu-language {
			
			.content { 
				display:flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 1rem;
			}
			a {
				flex-basis: 48%;
				width: 48%;
				display: block;
				padding: 1rem;
				color: $black;
				text-decoration: none;
				background:  $white;
				font-size: 1rem;
				white-space: nowrap;

				&:hover, &.active {
					background:  $primary;
					color: $white;
				}
			}

			img {
				width:1.25rem; 
				height: 1.25rem; 
				margin-right: 10px;
			}
		}
		
		&.menu-account {
			
			div.account {
				text-align: center;
				padding: 0 1rem 1rem;
			}
			
			div.links {
				
				a {
					background: $light-grey;
					display: flex;
					align-items: center;
					padding: 1rem;
					text-decoration: none !important;
					color: $black;
					
					&:hover, &:active {
						background: darken($light-grey, 2%);
					}
					
					&.disconnect {
						
						
						justify-content: flex-end;
						background: $dark;
						color: $white;
						text-align: right !important;
						
						span {
							color: $white;
							margin-right: .5rem;
						}

						&:hover, &:active {
							color: $white;
							background: $dark;
						}
					}
				}
			}
		}
		&.menu-basket {
			
			.empty {
				padding:1rem;
				background: $light-grey url('#{$asset-path}svg/basket.svg') center 1rem no-repeat;
				min-height: 200px; 
				display: flex; 
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				color: $dark-grey;
				font-size: .875rem;
				text-align: center;
			}
		}
	}

	.cart-qt {
		background: $red;
		border-radius: 50%;
		color: #fff;
		font-size: .8rem;
		line-height: 1;
		font-weight: bold;
		padding: .25rem .5rem;
		position: absolute;
		right: 0;
		bottom: .375rem;
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
		

		&.in {
			-webkit-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}

	.bread {
		
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display:flex;

			li {
				+ li {
					margin-left: 1rem;
				}

				a { 
					color: $dark-grey;
				}

				&.active a, a:hover, a:active {
					color: $black;
				}

				&.disabled a, &.disabled a:hover, &.disabled a:active {
					opacity: 0.65;
					color: $dark-grey;
					cursor: default;
					text-decoration: none;
				}
			}
		}

		.bread-step {
			height:  4px;
			background: $light-grey;
			border-radius: 2px;
			position:relative;
			margin-top: 1rem;

			&:before {
				content: '';
				position: absolute;
				height: 4px;
				top: 0;
				width: 0px;
				background: $primary;
				border-radius: 3px;
			}

			@for $i from 1 through 10 {
				$res:  10 * $i;
				&.step-#{$res}:before {
					width: 1% * $res; 
				}
			}
		}
	}
}

.full header:not(.scrolled) .navbar.light:not(.show) {
	background: transparent;
	box-shadow: none;
	
	&:hover {
		background: rgba($dark, .1);
	} 
	
	.brand { 
		.item {
			&.logo-skiset .logo {
				background-image: url('#{$asset-path}logo/skiset-invert.svg');
			}
		}

		.baseline {
			* {
				color: $white; 
			}
		}
	}

	.navbar-nav {
		li.nav-item {
			a.nav-link {
				color: $white; 
				
				&:hover, a:active {
					color: $white; 
				}
			}

			.navbar-toggler .hamb  span  {
				background: $white;	
			}
		}
	}
}


/* Responsive
-------------------------------*/

@include media-breakpoint-down('lg') {
	.navbar {
		.brand { 
			.item {
				&.logo-skiset {
					height: 40px;

					.logo {
						width: 154px;
						height: 40px;
					}
				}
					
				&.logo-partner img {
					max-width: 150px;
					max-height: 40px;
				}
			}
		}
		
		.navbar-nav li.nav-toggler{
			order: 5;
		}
	}
}

@include media-breakpoint-down('md') {
	
	.navbar {
		.brand { 
			.baseline {
				display: none;
			}
		}
	}
} 

@include media-breakpoint-down('sm') {	
	.navbar {
		.navbar-nav li.nav-item {
			position: static !important;
		}
		
		.dropdown-menu {
			min-width: 0;
			top:100px;
			left:0;
			right:0;
			width: 100%;
		}
		.bread {
			display: none;
		}
	}
}

@include media-breakpoint-down('xs') {
	.navbar {
		
		padding: 1.5rem 1rem;
		
		.navbar-nav li.nav-toggler,
		.brand-partner .logo-skiset {
			display: none;
		}
		
		.brand { 
			.item {
				&.logo-skiset {
					height: 34px;

					.logo {
						width: 130px;
						height: 34px;
					}
				}
					
				&.logo-partner img {
					max-width: 130px;
					max-height: 34px;
				}
			}
		}
				
		header.scrolled & {
			.brand:not(.brand-partner) { 
				.item {
					&.logo-skiset {
						.logo {
							width: 32px;
							background-size: 130px 34px;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up('xl') {
 
	.navbar {
		
		justify-content: space-around;
		
		.navbar-toggler {
			display: none;
		}
		
		.menu-toggler {
			display: block;
			position: static;
			background: none;
			min-width:0;
			padding:0;
			box-shadow : none;
			
			&:before {
				content: none;
			}
		}
		
		.navbar-menu {
			flex-direction: row;
			align-items: center;
			
			li {
				margin: 0 .5rem;

				a {
					color:$black;
					padding: .875rem .5rem;
					background:  none;
					white-space: nowrap;
					border-radius: .5rem;
					&:hover, &:active {
						background: rgba(0,0,0,.05);
						color:$dark;
					}
				}

				&.active a {
					font-weight: bold;
					background:  none;
					color:$black;
				}
			}
		}
	}
	
	.full header:not(.scrolled) .navbar.light:not(.show) {

		.navbar-menu {
			li {
				a {
					color: $white; 
				} 

				&.active a {

					color:$white;

					&:after {
						content:'';
						position: absolute;
						left: 1rem;
						right: 1rem;
						height: 2px;
						bottom:0;
						background: rgba($white, 1);
					}
				}
			}
		}
	} 
}