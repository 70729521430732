input::after,
input::before,
select::after,
select::before,
textarea::after,
textarea::before {
	@include style-box-sizing(border-box);
}

.form-group input, .form-group select, .form-group textarea
.checkbox input, .radio input,
.switch-material input {
	@include style-appearance(none);

	&, &:invalid {
		@include style-box-shadow(none);
	}
}

fieldset {
	margin: 2rem 0 1rem 0;
	padding: 0;
	border: none;
}

.form-group, .radchk-group {
	position:relative;
	font-family: $font-family-base;
	font-size: 1rem;
	line-height: 1.5;
}

.control-help {
	color: $dark-grey;
	font-size: .75rem;
	line-height: 1.5;
	position: relative;
	width: 100%;
	margin: .25rem 0;
}

.control-error {
	color: $red;
	font-size: .75rem;
	line-height: 1.5;
	position: relative;
	width: 100%;
	margin: .25rem 0;
	display: none;
}

@mixin control-label-up {
	color: $gray-900;
	font-size: .75rem;
	top: .5rem !important;
	left: 2.0625rem;
}

.form-group {
	margin : 0 0 2rem 0;

	.control-label {
		color: $gray-900;
		font-size: 1rem;
		line-height: 1.5;
		font-weight: 500;
		position: absolute;
		top: 1.0625rem;
		left: 2.0625rem;
		z-index: 1;
		pointer-events: none;
		@include style-transition(0.2s ease all);
	}

	.bar {
		position: relative;
		display: block;

		&::before {
			content: ' ';
			height: 2px;
			width: 0;
			left: 50%;
			bottom: -1px;
			position: absolute;
			background: $primary;
			@include style-transition((left 0.28s ease, width 0.28s ease));
			z-index: 2;
		}
	}

	input, textarea, select {
		color: $black;
		font-family: $font-family-base;
		font-size: 1rem;
		line-height: 1.5;
		background-color: $white;
		border-width: 1px;
		border-style: solid;
		border-color: $stroke;
		border-radius: 2rem;
		display:block;
		width: 100%;
		outline: none;

		&:focus {
			background-color: $white;
			border-color: transparent;
			box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
			border-radius: 0;

			~ .bar::before {
				width: 100%;
				left: 0;
			}
		}

		&:disabled{
			color: #CCC;

			~ .control-label {
				color: #CCC;
			}
		}
	}

	input[type="file"] {
		border: none;
		padding-left: 0;
		padding:1rem 0;
		box-shadow:none !important;

		& ~ .control-label { // .0625 => + 1px border
			top: 0 !important;
			left: 0 !important;
		}

		& ~ .bar {
			display: none;
		}
	}

	input:not([type='file']), textarea {

		padding:1rem 2rem;

		&::placeholder {
			color: #AAA;
			opacity: 1;
		}

		&:not(:focus)::placeholder {
			color: transparent;
		}

		& ~ .control-label { // .0625 => + 1px border
			top: 1.0625rem;
			left: 2.0625rem;
		}

		&:focus,&:valid:not(.is-empty),&.has-value {

			padding-top:1.5rem;
			padding-bottom:.5rem;

			~ .control-label {
				@include control-label-up;
			}
		}
	}

	select {
		background-image: $select_img_down;
		background-repeat: no-repeat;
		background-position: right 2rem center;
		padding:1.5rem 3rem .5rem 2rem;
		-moz-padding-start: 1.625rem;
	}

	select ~ .control-label,
	.control-label.up {
		@include control-label-up;
	}

	.pop-over {
		position: absolute;
		z-index: 2;
		right: 1rem;
		top:50%;
		transform: translateY(-50%);

		& ~ input:not([type='file']), & ~ textarea, & ~ select {
			padding-right: 3.5rem;
		}
	}

	&.form-light {

		.control-label {
			left: .375rem !important;
		}

		input, textarea, select {
			border: none;
			border-bottom: 1px solid $stroke;
			border-radius: 0 !important;

			&:focus {
				border-color: $stroke !important;
				box-shadow: none !important;
			}
		}

		input, textarea {

			padding-left: .375rem;
			padding-right: .375rem;

			& ~ .control-label,
			&:focus ~ .control-label,&:valid:not(.is-empty) ~ .control-label,&.has-value ~ .control-label  {
				left: .375rem !important;
			}
		}

		textarea {
			resize: none;
		}

		select {
			background-position: right center;
			padding-left:.375rem;
			padding-right: 1.5rem;
			-moz-padding-start: 0;
		}

		.pop-over {
			right: 0;

			& ~ input:not([type='file']), & ~ textarea, & ~ select {
				padding-right: 2.5rem;
			}
		}
	}

}

.radchk-group {

	margin: 0 0 1rem;

	.checkbox, .radio {

		margin: 0;

		label {
			position: relative;
			cursor: pointer;
			text-align: left;
			color: $black;
			display: flex;
			align-items: center;
			margin: 0;

			&:hover .helper {
				color: $stroke-hover;
			}
		}

		.helper {
			color: $stroke;
			margin: 0.125rem 1rem 0 0;
			cursor: pointer;
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			flex-shrink: 0;
			position:relative;
			@include style-user-select(none);

			&::before,&::after {
				content: '';
				position: absolute;
				left: 0; top:0;
				margin: 0;
				width: 1.25rem; height: 1.25rem;
				background: $white;
				@include style-transform( 0.2s ease);
				border: 1px solid currentColor;
			}

			&::after {
				background-color: $primary;
				border-color: $primary;
				@include style-transform-scale(0);
			}
		}

		input {
			width: auto;
			opacity: 0.00000001;
			position: absolute;
			left: 0;

			&:checked ~ .helper {
				&::before {
					color: $primary;
				}
				&::after {
					@include style-transform-scale(0.6);
				}
			}
		}
	}

	.radio + .radio,
	.checkbox + .checkbox {
		margin: .5rem 0 0 0;
	}

	&.inline {
		display: flex;
		flex-wrap: wrap;

		.radio, .radio + .radio,
		.checkbox, .checkbox + .checkbox {
			margin: 0 1rem 0 0;
		}
	}
}

.checkbox {
	.helper {
		&::before,&::after {
			border-radius: 4px;
		}
	}
}

.radio {
	.helper {
		&::before,&::after {
			border-radius: 50%;
		}
	}
}


.has-error.form-group input,
.has-error.form-group select,
.has-error.form-group textarea {
	border-radius: 0 !important;
	border-color: transparent !important;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08) !important;
}
.has-error.form-light input,
.has-error.form-light select,
.has-error.form-light textarea {
	border-radius: 0 !important;
	border-color: $stroke !important;
	box-shadow: none !important;
}

.has-error.form-group .control-help,
.has-error.radchk-group .control-help {
	display: none !important;
}
.has-error.form-group .control-error,
.has-error.radchk-group .control-error  {
	display: inline-block !important;
}

.has-error.form-group .control-label.control-label,
.has-error.form-group .control-label.up,
.has-error.radchk-group .helper  {
	color: $red !important;
}
.has-error .bar::before {
	background: $red !important;
	width: 100% !important;
	left: 0 !important;
}