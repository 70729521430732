.error-wrapper {
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4rem;
	
	h2 {
		font-size: 10rem;
		font-weight: 100;
	}
}