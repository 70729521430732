#gitVersion:before {
	content: "Git rev : ";
}

#gitVersion {	
	position: fixed;
	bottom: 0;
	right: 0;
	background: #000;
	color: #FFF;
	font-size: 14px;
	padding: 3px 10px;
	font-style: oblique;
	z-index: 1300;
} 

.presentation #gitVersion {
	display: none;
}