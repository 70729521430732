$catalog-color: (
	"black":		#000004,
	"blue":			#0f4f79,
	"brown":		#4e2f2d,
	"diamond":		#6d778a,
	"green":		#095615,
	"or":			#b57921,
	"orange":		#ea8400,
	"purple":		#6840c6,
	"red":			#80100f
);

@each $color, $value in $catalog-color {
	.bd-#{$color} {
		border-color: $value !important;
	}

	.txt-#{$color} {
		color: $value !important;
	}
}

/* global */
/* ---------------------------------------------------------------------------*/

.material {
	padding: 2rem 0;

	position: relative;
	background: #5494db url('#{$asset-path}images/bg/book.jpg') no-repeat scroll 0 0;
	background-size : cover;
}

.filter-material {
	display:flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0 1rem 2rem 1rem;
	justify-content: center;

	> div {
		margin-bottom: 1rem;
		padding : 0;

		&.filter-catalog {
			margin-right: 2rem;
		}

		&.filter-category {
			.nav-pills {
				display: none;

				&.active {
					display:flex;
				}
			}
		}

		.nav {
			display:flex;
			justify-content: center;
		}

		.nav-pills {
			flex-wrap: nowrap;



			.nav-item + .nav-item {
				margin-left: .5rem;
			}

			.nav-link {
				padding: .875rem 1.5rem;
				background: none;
				color: $white;
				font-weight: normal;
				border: 1px solid $white;
				display:inline-block;
				position:relative;
				transition: $transition_global;
				border-radius: 2rem;
				white-space: nowrap;

				&:hover, &.active {
					color: $dark;
					background: $white;
				}
				&.active::after {
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 9px solid #ffffff;
					bottom: -9px;
					content: "";
					display: block;
					height: 0;
					left: 50%;
					margin-left: -7px;
					position: absolute;
					width: 0;
				}
			}
		}
	}

	@include media-breakpoint-down('sm') {
		flex-direction: column;
		align-items: center;

		> div {
			&.filter-catalog {
				margin-right:0;
			}

			.nav-pills {
				flex-wrap: wrap;

				.nav-item {
					margin-bottom: 1rem;
				}

				.nav-link {
					padding: .5rem;
					border: 1px solid $white;

					&:hover, &.active {

					}
				}

			}
		}
	}
}

.list-material {
	position:relative;
	max-width: 100vw;
	margin: 2rem 0;
	overflow: hidden;
}

.catalog-items {
	display: flex;
	justify-content: center;

	.mobile & {
		justify-content: flex-start;
	}

	&.owl-carousel {
		visibility: hidden;
		opacity: 1 !important;
		flex-direction: column;

		.owl-stage-outer .owl-stage {
			display: flex;
			justify-content: stretch;
			padding-bottom: .25rem;

			.owl-item {
				float: none;
				display: flex;

				.item {
					flex: 1;
					width: 100%;
					max-width: none;
				}

				img {
					display: initial;
					width:auto;
				}
			}
		}
	}

	&.owl-loaded {
		visibility: visible;
		justify-content: center;
		display: flex;
	}

	.item {
		flex: 20% 0 0;
		width: 20%;
		max-width: 20%;
		display: flex;
		flex-direction: column;
	}
	.inner-item {
		border: 1px solid $white;
		background: $white;
		margin: 0 1.5rem;
		flex-grow: 1;
		border-radius: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-shadow: 0 4px 10px -2px rgba(0,0,0,.2);
		padding:2rem;
		position: relative;
		overflow: hidden;



		&.sold-out {
			&:before {
				content: ' ';
				position: absolute;
				top: 0; left:0; bottom: 0; right: 0;
				width: 100%; height: 100%;
				background: rgba($light-grey, .5);
				z-index: 10;
			}
		}
	}

	.exclu {
		font-size: .875rem;
		color:$dark;
	}

	.desc {
		color: $dark-grey;
		font-size:.875rem;
		line-height: 1.25;
		margin-bottom: 1rem;
		flex: 1;
	}

	.slops {
		span {
			display: inline-block;
			cursor: pointer;
			height: 20px; width: 20px;
			overflow: hidden;
			background: url('#{$asset-path}images/bg/slops.png') top left no-repeat;
			border-radius:10px;

			&.slop_green { background-position:0px 0px; }
			&.slop_blue { background-position:-20px 0px; }
			&.slop_red { background-position:-40px 0px; }
			&.slop_black { background-position:-60px 0px; }
			&.slop_off { background-position:-80px 0px; }
			&.slop_park { background-position:-100px 0px; }

			&.slop_off_fr { background-position:-80px -20px; }
		}

		span + span {
			margin-left: 3px;
		}
	}

	.example {
		margin: 1rem 0;
		min-height: 42px;
		text-align: center;

		> span {
			color: $dark-grey;
			font-size: 0.75rem;
			text-overflow: ellipsis;
			overflow: hidden;
			text-align: center;
			white-space: nowrap;
			display:block;
			line-height: 1.2;
		}

		a {
			font-size: 0.875rem;
		}

	}

	.validation {
		position: relative;
		text-align:center;

		.btn {
			padding: 1rem;
		}
	}

	&.selectable-items {

		&.owl-carousel  .owl-stage-outer .owl-stage  .owl-item.active .inner-item,
		> div.item .inner-item {
			border: 1px solid $green;
		}

		.validation {
			display: none;
		}
	}


	@media (min-width: 1200px) and (max-width: 1600px) {
		.item {
			flex: 25% 0 0;
			width: 25%;
			max-width: 25%;
		}
	}

	@include media-breakpoint-down('lg') {
		.item {
			flex: 33.33% 0 0;
			width: 33.33%;
			max-width: 33.33%;
		}
	}

	@include media-breakpoint-down('md') {
		.item {
			flex: 50% 0 0;
			width: 50%;
			max-width: 50%;
		}
	}

	@include media-breakpoint-down('sm') {
		.item {
			width: 100%;
			flex:none;
			max-width:none;
			margin-bottom: 2.5rem;
		}
	}
}


.booking-cadd  {
	.heading {
		font-weight: 700;
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
}

.cadd-pack {
	display: flex;
	position: relative;
	-flex-wrap: wrap;

	.illustration {
		position: relative;
		overflow: hidden;
		width: 5rem;

		img {
			position: absolute;
			top:0;
			max-width: 4rem;
		}
	}

	.description {
		flex:1;
	}

	.offer-title {
		font-size: 1rem;

		span.offer {
			font-size: 1.2rem;
			font-weight: 200;
		}

		span.skier {
			display : block;
			color:$dark;
		}
	}

	.infos {
		color: $dark-grey;
		font-size: .875rem;

		> span {
			display: block;
		}

		.percent {
			color: $black;
			font-weight: 500;

			&.success {
				color: $green;
			}
			&.danger {
				color: $red;
			}
			&.warning {
				color: $orange;
			}

			@include media-breakpoint-down('md') {
				display: none;
			}
		}
	}

	.measures {
		display:flex;
		flex-wrap: wrap;

		.measure {
			color: $dark-grey;
			background: $light-grey;
			padding: .25rem .5rem;
			border-radius : 1rem;
			font-size: .875rem;
			margin: .25rem 0;

			+ .measure {
				margin-left: .25rem;
			}

			span.icon {
				color: $black;
				margin-right: .25rem;
			}
		}
	}

	.act {
		margin-top: 1rem;
		display:flex;
		justify-content: space-between;

		.links {
			order: 2;
		}
		.price {
			font-weight: 700;
			order: 1;
		}

		@include media-breakpoint-up('md') {
			margin : 0;
			flex-direction: column;
			position: absolute;
			right: 0; top: 0; bottom:0;
			height: 100%;


			.links {
				order: 1;
			}
			.price {
				order: 2;
			}
		}
	}
	.show-price {
		margin-top: 1rem;
		font-weight: 700;
	}
}

.cadd-message {
	border-bottom: 1px solid $stroke;
	margin-bottom: 2rem;

	.link {
		display:flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 1rem;
	}

	&.has-message .link {
		display: none;
	}

	.message-box {
		margin-bottom: 1rem;
	}

	.message-comment {
		color: $dark-grey;
		font-size: .875rem;
	}
	.message-action {
		display:flex;
		justify-content: flex-end;
		align-items: center;

		a + a {
			margin-left: .5rem;
		}
	}
}

.cadd-stay {

	border-bottom: 1px solid $stroke;
	margin-bottom: 2rem;

	.shop {
		position: relative;
		margin-bottom: 1rem;
	}

	.shop-name {
		font-weight: 500;
		font-size: 1rem;
	}

	.info,
	.stay{
		font-size: .875rem;
		color:$dark-grey;
		margin-bottom: 1rem;
	}

	.link {
		text-align: right;
	}
}

.cadd-promo {
	.cadd-promo-heading {
		display: flex;
		justify-content: space-between;

		a[data-show="0"] {
			display:none;
		}
	}

	.cadd-promo-result {
		margin-top: .5rem;
		border: 1px solid $green;
		background: lighten($green, 50);
		padding: 1rem;
		line-height: 1.2;

		span {
			display: block;
			color: $black;
			margin-right: 1rem;
			font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;

			&:after {
				font-family: 'skiset';
				content: '\ea10';
				margin-left: .5rem;
				color: $green;
			}
		}
	}
	.cadd-promo-form {
		margin-top: .5rem;
		display : none;

		.input-group {
			position: relative;
			display: flex;
			flex-wrap: no-wrap;
			align-items: stretch;
			width: 100%;

			.form-group {
				flex-grow: 1;
				margin: 0;

				input {
					border-radius: 3px 0 0 3px;
					border-right:none;

					&:focus {
						border-color: $green;
						box-shadow: none;
						border-radius: 3px 0 0 3px;
					}
				}
			}

			.btn {
				padding: 1rem 1.5rem 1rem 1rem;
				border-radius:  0 3px 3px 0;
				border-left:none;
				box-shadow: none !important;
			}
		}
	}

	&.open {

		.cadd-promo-heading {
			a[data-show="0"] {
				display:inline-flex;
			}
			a[data-show="1"] {
				display:none;
			}
		}

		.cadd-promo-result {
			display: none;
		}
		.cadd-promo-form {
			display: block;
		}
	}
}

.cadd-quote {

	.quote-group {
		margin-bottom: 1rem;
	}

	.quote-line {
		display:flex;
		color: $text-muted;

		em {
			font-size: 0.9rem;
			display:block;
		}
	}
	.quote-label {
		flex-grow: 1;
	}
	.quote-value {
		flex-grow: 0;
		font-weight: 500;
		color: $black;
	}

	.quote-subtotal {
		color: $black;
		border-bottom: 1px solid $gray-400 !important;
		padding-bottom: 1rem;
		font-weight: 700;
	}

	.quote-total {
		font-weight: 700;
		color: $black;

		.quote-value {
			font-weight: 700;
			font-size: 1.125rem;
		}
	}
}

.offer-title {
	border-left: .5rem solid $dark;
	padding-left: .5rem;
	font-size: 1.2rem;
	line-height: 1.1;
	font-weight: 400;
	margin-bottom: 1rem;

	span.offer {
		font-size: 1.5rem;
		font-weight: 200;
	}

	span.level,
	span.age {
		display : block;
		color:$dark-grey;
		font-size: .875rem;
	}
}

.offer-prices  {
	display: flex;
	align-items: center;
	line-height: 1;
	justify-content: space-between;
	width: 100%;

	.prices {
		div {
			display: flex;
			flex-direction: column;
			font-size: 0.8rem;


			&.price-shop {
				color: $gray-500;
				margin-bottom: .5rem;
			}

			&.price-final {
				span {
					font-weight: 700;
					font-size: 1.25rem;
				}

			}
		}
	}

	span.discount {
		flex-shrink: 0;
		display: inline-flex;
		align-items: center;
		width: 50px;
		height: 50px;
		color: #FFF;
		font-weight: 700;
		background: #D41B1B;
		border-radius: 50%;
		justify-content: center;
		font-size: 1rem;
		margin-left: .5rem;
	}
}

.offer-composition {
	display: flex;
	justify-content: space-between;

	.product {
		position:relative;
		flex-basis: 90px;
		width: 90px;
		flex-shrink: 0;

		img {
			max-width: 120px;
			max-height: 300px;
			width: auto !important;
		}

		&.ski, &.snowblade, &.nordiq, &.rando, &.skate {
			background: url('#{$asset-path}images/bg/ski-stick.png') 40px 55% no-repeat;
		}
		&.shoes {
			flex-basis: 120px;
			width: 120px;
		}
	}

	.product-side {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
		flex: 1;
	}

	.options {

		display: flex;
		flex-direction:column;
		justify-content: center;
		align-items: flex-start;
		margin: 0;

		.stepon {
			margin: -2rem 0 1rem 0;
		}

		.option {

			cursor: pointer;

			img {
				opacity: 0.2;
				max-width: 42px;
				margin-right: .5rem;
				filter: grayscale(1);
				transition: 0.2s ease all;
			}


			label > span {
				display:block;
				font-size: .875rem;

				span.icon-sk-info {
					font-size: 1.2rem;
				}

				span.info {
					display:block;
					color:$dark-grey;
					font-size: .75rem;
				}
			}

			.helper {
				margin-right: .25rem;

				/*&::after, &::before {
					top: 1rem;
				}*/
			}
			&.active {
				img {

					opacity: 1;
					filter: grayscale(0);
				}
			}
		}

		> .option-group + .option,
		> .option + .option {
			margin-top:1.5rem;
		}
	}
}

// Misc Casse
// -----------------------------------------------------------------------------

.misc-casse {

	position:relative;
	margin: 0;
	border-radius: 1rem 1rem 0 0 ;
	display: flex;
	flex-direction : column;
	background:#2a91c2;

	.main {
		padding: 1rem  1rem  1rem 140px;
		background: transparent  url('#{$asset-path}images/bg/pictos_garantie.png') 20px 50% no-repeat;
		color:  $white;

		@include media-breakpoint-down('xs') {
			padding: 1rem;
			background: none;
		}
	}

	.title{
		font-size: 1.5rem;
		font-weight:500;
		text-transform: uppercase;
	}
	.cover  {
		font-size: 1.2rem;
		font-weight:700;
		color:#ffe400;
		text-transform: uppercase;
	}
	.tarif {
		font-size: 1rem;
	}

	ul.checkmark {
		margin-top: 1rem;

		li {
			color:  $white;

			&:before {
				color:  $white;
			}

			&:first-child {
				color:  #ffe400;

				&:before {
					color:  #ffe400;
				}
			}
		}
	}

	.info {
		padding: 0 1rem 1rem 1rem;
		font-size: .75rem;

		color:  $white;
	}
}


.misc-casse-select {
	background: $white;
	border: 1px solid #2a91c2;
	color: $dark;
	padding: 1rem ;
	margin-bottom: 3rem;
	border-radius: 0 0 1rem 1rem;

	&.radchk-group .checkbox .helper::before {
		color: #2a91c2;
	}
	&.radchk-group .checkbox .helper::after {
		background-color: #2a91c2;
		border-color: #2a91c2;
	}
}

// Misc 
// -----------------------------------------------------------------------------

.misc-drive {
	margin-bottom: 2rem;
	border-bottom: 1px solid $stroke;

	.heading {
		font-weight: 700;
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}

	.radchk-group {

		margin-bottom: 2rem;

		.checkbox {

			label {
				margin:0;
			}
			.control-help {
				display: block;
				font-size: .875rem;
				color: $dark-grey;
				margin:0 0 0 2rem;;
			}
		}
	}
}