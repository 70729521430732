.post-slide{
	overflow: hidden;
	-margin-right: 15px;
	
	.post-img {
		position: relative;

		img {
			width: 100%;
			height: auto;
		}
	}
	
	.post-resort{
	//	background: $blue-cyan;
		color:#fff;
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		padding: .250rem .5rem;
		text-align: center;
		transition:all 0.50s ease;
	}
	
	.post-review {
		padding: 1rem 0;
	//	border-top: 3px solid $blue-cyan;
	}
	
	&:hover .post-review{
		 border-top-color:$primary;
	}
}  

.post-slide .post-title{
    margin:0 0 10px 0;
}
.post-slide .post-title a{
    font-size:14px;
    color:#333;
    text-transform:uppercase;
}
.post-slide .post-title a:hover{
    text-decoration:none;
    font-weight: bold;
}
.post-slide .post-bar{
    padding:0;
    list-style:none;
    text-transform:uppercase;
    position: relative;
    margin-bottom: 20px;
}
.post-slide .post-bar:after,
.post-slide .post-bar:before{
    border: 1px solid #38cfd8;
    bottom: -10px;
    content: "";
    display: block;
    position: absolute;
    right: 36%;
    width: 25px;
}
.post-slide .post-bar:before{
    border: 1px solid #ec3c6a;
    right: 32%;
}
.post-slide .post-bar li{
    color:#555;
    font-size:10px;
    margin-right:10px;
    display:inline-block;
}
.post-slide .post-bar li a{
    font-size: 13px;
    text-decoration:none;
    text-transform:uppercase;
    color:#ec3c6a;
}
.post-slide .post-bar li a:hover{
    color:#ec3c6a;
}
.post-slide .post-bar li i{
    color:#777;
    margin-right:5px;
}
.post-slide .post-description{
    font-size:12px;
    line-height:21px;
    color:#444454;
}
.owl-theme .owl-controls{
    margin-top: 30px;
}
.owl-theme .owl-controls .owl-page span{
    background: #fff;
    border: 2px solid #37a6a4;
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
    background: #37a6a4;
}
@media only screen and (max-width: 990px) {
    .post-slide .post-img {
        width:100%;
    }
    .post-slide .post-review{
        width:100%;
        border-bottom: 4px solid #ec3c6a;
    }
    .post-slide .post-bar:before{
        left: 0;
    }
    .post-slide .post-bar:after{ 
        left: 25px;
    }
} 