.litepicker {
	font-size: 1em;
	display: none;

	button {
		border: none;
		background: none;
	}

	.container {
		&__main {
			display: flex;
		}
		&__months {
			display: flex;
			flex: 1 1 100%;
			flex-wrap: wrap;
			background-color: $white;
			box-sizing: border-box;
			justify-content: space-between;

			&.columns-2 .month-item {
				width: 48%;
				flex-basis: 48%;
			}

			&.columns-3 .month-item {
				width: 30%;
				flex-basis: 30%;
			}

			&.columns-4 .month-item {
				width: 24%;
				flex-basis: 24%;
			}

			&.split-view {
				.month-item {
					&-header {
						.button-previous-month,
						.button-next-month {
							visibility: visible;
						}
					}
				}
			}

			.month-item {
				width: 100%;
				flex-basis: 100%;

				box-sizing: content-box;

				&-header {
					display: flex;
					width: 100%;
					justify-content: space-between;
					font-weight: 500;
					padding: 1rem;
					text-align: center;
					align-items: center;
					color: $black;

					div {
						flex: 1;

						> .month-item-name {
							margin-right: .5rem;
						}

						> .month-item-year {
							padding: 0;
						}
					}

					.reset-button {
						color: $dark-grey;

						> svg {
							fill: $dark-grey;
						}

						* {
							pointer-events: none;
						}

						&:hover {
							color: $green;

							> svg {
								fill: $green;
							}
						}
					}

					.button-previous-month,
					.button-next-month {
						visibility: hidden;
						text-decoration: none;
						padding: 3px 5px;
						border-radius: 3px;
						transition: color 0.3s, border 0.3s;
						cursor: default;

						* {
							pointer-events: none;
						}
					}
					.button-previous-month {
						color: $dark-grey;

						> svg,
						> img {
							fill: $dark-grey;
						}

						&:hover {
							color: $green;

							> svg {
								fill: $green;
							}
						}
					}
					.button-next-month {
						color: $dark-grey;

						> svg,
						> img {
							fill: $dark-grey;
						}

						&:hover {
							color: $green;

							> svg {
								fill: $green;
							}
						}
					}
				}

				&-weekdays-row {
					display: flex;
					width: 100%;
					justify-self: center;
					justify-content: flex-start;
					color: $red;
					font-weight: 500;

					> div {
						padding: 5px 0;
						font-size: 85%;
						flex: 1;
						width: 14.28%;
						text-align: center;
					}
				}

				&:first-child {
					.button-previous-month {
						visibility: visible;
					}
				}

				&:last-child {
					.button-next-month {
						visibility: visible;
					}
				}

				&.no-previous-month {
					.button-previous-month {
						visibility: hidden;
					}
				}

				&.no-next-month {
					.button-next-month {
						visibility: hidden;
					}
				}
			}
		}

		&__days {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-self: center;
			justify-content: flex-start;
			text-align: center;
			box-sizing: content-box;

			> div,
			> a {
				height:3.5rem;
				padding: 1rem;
				width: 14.28%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.day-item {
				color: $black;
				text-align: center;
				text-decoration: none;
				border-radius: 1.75rem;
				transition: border 0.3s;
				cursor: default;

				&:hover {
					color: $green;
					box-shadow: inset 0 0 0 1px $green;
				}

				&.is-today {
					color: $red;
				}

				&.is-locked {
					color: #9e9e9e;

					&:hover {
						color: #9e9e9e;
						box-shadow: none;
						cursor: default;
					}
				}

				&.is-in-range {
					color: $dark;
					background-color: lighten($green, 40%);
					border-radius: 0;
				}

				&.is-start-date {
					color: $white;
					background-color: $green;
					border-top-left-radius: 28px;
					border-bottom-left-radius: 28px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;

					&.is-flipped {
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border-top-right-radius: 28px;
						border-bottom-right-radius: 28px;
					}
				}

				&.is-end-date {
					color: $white;
					background-color: $green;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: 28px;
					border-bottom-right-radius: 28px;

					&.is-flipped {
						border-top-left-radius: 28px;
						border-bottom-left-radius: 28px;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}
				}

				&.is-start-date.is-end-date {
					border-top-left-radius: 28px;
					border-bottom-left-radius: 28px;
					border-top-right-radius: 28px;
					border-bottom-right-radius: 28px;
				}

				&.is-highlighted {
					color: $black;
					background-color: #ffeb3b;;
				}
			}

			.week-number {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #9e9e9e;
				font-size: 85%;
			}
		}

		&__footer {
			text-align: right;
			padding: 10px 5px;
			margin: 0 5px;
			background-color: #fafafa;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;

			.preview-date-range {
				margin-right: 10px;
				font-size: 90%;
			}

			.button-cancel {
				background-color: #9e9e9e;
				color: #FFF;
				border: 0;
				padding: 3px 7px 4px;
				border-radius: 3px;

				* {
					pointer-events: none;
				}
			}

			.button-apply {
				background-color: $green;
				color: #FFF;
				border: 0;
				padding: 3px 7px 4px;
				border-radius: 3px;
				margin-left: 10px;
				margin-right: 10px;

				&:disabled {
					opacity: 0.7;
				}

				* {
					pointer-events: none;
				}
			}
		}

		&__tooltip {
			position: absolute;
			margin-top: -4px;
			padding: 4px 8px;
			border-radius: 4px;
			background-color: $white;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
			white-space: nowrap;
			font-size: 11px;
			pointer-events: none;
			visibility: hidden;

			&:before {
				position: absolute;
				bottom: -5px;
				left: calc(50% - 5px);
				border-top: 5px solid rgba(0, 0, 0, 0.12);
				border-right: 5px solid transparent;
				border-left: 5px solid transparent;
				content: "";
			}

			&:after {
				position: absolute;
				bottom: -4px;
				left: calc(50% - 4px);
				border-top: 4px solid $white;
				border-right: 4px solid transparent;
				border-left: 4px solid transparent;
				content: "";
			}
		}
	}
}
