$font-size-base:				1rem;
$font-family-base:				'Poppins', helvetica;
$asset-path:					'../../assets/';
$font-path:						'#{$asset-path}font/';
$prod-static-url:				'https://static.skiset.com/';

$h1-font-size:					$font-size-base * 2;
$h2-font-size:					$font-size-base * 1.75;
$h3-font-size:					$font-size-base * 1.5;
$h4-font-size:					$font-size-base * 1.25;
$h5-font-size:					$font-size-base * 1;
$h6-font-size:					$font-size-base * 1;


$blue:							#4b8ad1;
$red:							#AF282F; 
$green:							#24BA5E;
$white:							#FCFDFF;
$black:							#4C4C4D; 

$facebook:						#3B5998;
$twitter:						#00ACED;
$instagram:						#F77737;

$primary:						$green; 
$dark-primary:					#1C944B; 
$darker-primary:				#16753B; 

$gray-100:						#f8f9fa;
$gray-900:						#2A2A2A; // logo dark

$light-grey:					#F0F0F0;
$dark-grey:						#7E7E80;

$stroke:						#C9C9CB; 
$stroke-hover:					#777778; 

$body-color:					$black;
$body-bg:						#FFF;
$text-muted:					$dark-grey;

$grid-gutter-width:				4rem;

$header-height:					130px;

$container-max-widths: (
	sm: 100%,
	md: 95%,
	lg: 960px,
	xl: 1280px
); 

/* Remove border radius */
$border-radius:					0;
$border-radius-lg:				0;
$border-radius-sm:				0;

$transition_global:				all 0.3s ease-in-out;

$shadow_100:					0px 1px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);

$select_img_down:				url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSAwLjU4OTk2Nkw2IDUuMTY5OTdMMS40MSAwLjU4OTk2NkwwIDEuOTk5OTdMNiA3Ljk5OTk3TDEyIDEuOTk5OTdMMTAuNTkgMC41ODk5NjZaIiBmaWxsPSIjN0U3RTgwIi8+Cjwvc3ZnPgo=);
$select_img_up:					url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSA3LjQxIDYgMi44MyAxLjQxIDcuNDEgMCA2IDYgMCAxMiA2IDEwLjU5IDcuNDFaIiBmaWxsPSIjN0U3RTgwIi8+Cjwvc3ZnPgo=);

$select_img_down_light:			url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSAwLjU4OTk2Nkw2IDUuMTY5OTdMMS40MSAwLjU4OTk2NkwwIDEuOTk5OTdMNiA3Ljk5OTk3TDEyIDEuOTk5OTdMMTAuNTkgMC41ODk5NjZaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==);
$select_img_up_light:			url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC41OSA3LjQxIDYgMi44MyAxLjQxIDcuNDEgMCA2IDYgMCAxMiA2IDEwLjU5IDcuNDFaIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPg==);