html {
	height: 100%;
	position: relative;
}
body {
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	min-height: 100%;
	margin: 0;
	position: relative;

	&.no-overflow {
		overflow: hidden;
		/*position: relative;
		left:0; right: 0;bottom:0;top: 0;
		max-height: 100%;
		//z-index: -1;

	touch-action: none;
		-ms-touch-action: none;*/
	}

	&.flashsell {
		margin-bottom: 200px;
	}
}

header, main, footer {
	position: relative;
}

main {

	margin: 0;
	min-height: 100vh;

	.no-overflow & {
		max-height: 100%;
		overflow: hidden;
	}

	/* Avoid Chrome to see Safari hack */
	@supports (-webkit-touch-callout: none) {
		body {
			min-height: -webkit-fill-available;
		}
	}

	&.full {
		height: 100%;
	}
}

// Margin Sk

$spacersSk: 1, 2, 3, 4, 5, 6;

@each $prop, $abbrev in (margin: m, padding: p) {
	@each $size in $spacersSk {
		.#{$abbrev}y-sk-#{$size} {
			#{$prop}-top: #{$size}rem !important;
			#{$prop}-bottom: #{$size}rem !important;
		}
		.#{$abbrev}t-sk-#{$size} {
			#{$prop}-top: #{$size}rem !important;
		}
		.#{$abbrev}b-sk-#{$size} {
			#{$prop}-bottom: #{$size}rem !important;
		}
		.#{$abbrev}-sk-#{$size} {
			#{$prop}: #{$size}rem !important;
		}
	}
}

a {
	color: $red;

	@include hover() {
	  color: darken($red, 25%);
	}
}

b, strong {
	font-weight: 600;
}

.text-red, .text-red * {
	color: $red !important;
}

.clk-inside {
	cursor: pointer;
}

/* Headers
------------------------------*/

.heading {
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: 2.5rem;

	&.light {
		font-size: 1.5rem;
	}

	span {
		color: $red;
	}
}

.sub-heading {
	font-weight: 700;
	font-size: 1.2rem;
	margin-bottom: 1rem;

	span {
		color: $red;
	}
}

span.sktrade {
    font-weight: 600;
    text-transform: uppercase;
}

blockquote {
    margin: 0 0 1rem;
    padding: 1rem;
    border-left: 5px solid #eee;
}

.bloc-content {
	margin: 0 0 3rem !important;

	*:not(a) {
		color: lighten($body-color, 15);
	}

	span, h1, h2,h3, h4,h5, h6 {
		color: $body-color;
	}

	/**:not(a), *:not(li), *:not(h1), *:not(h2), *:not(h3), *:not(h4), *:not(h5), *:not(h6) {
		color: $red; //lighten($body-color, 15);
	}*/
}

.link-icon {
	border: 1px solid $stroke;
	text-decoration:none !important;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 2rem;
	transition: background 0.8s;
	background-color: $white;
	color: $black !important;
	font-size: 1rem !important;
	font-weight: normal !important;

	span {
		color: $black !important;
		font-size: 1rem !important;
		font-weight: normal !important;
	}

	&:hover, &.active {
		background-color: $light-grey;
	}

	&:active {
		background-color: darken($light-grey, 5);
	}
}

.link-light {
	border: 1px solid $stroke;
	text-decoration:none !important;
	border-radius: .5rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: .5rem;
	color: $black !important;
	transition: background 0.8s;
	background-color: $white;

	&:hover, &.active {
		background-color: $light-grey;
	}

	&:active {
		background-color: darken($light-grey, 5);
	}
}

/* List
------------------------------*/

ul.checkmark {
	list-style: outside none none;
	margin: 0 0 1rem 2rem; padding: 0;

	li{
		position:relative;
		&:before {
			position: absolute;
			font-family: 'skiset';
			content: '\ea10';
			color: $primary;
			margin: 0 .5rem 0 -2rem;
		}
	}
}

ul.contact {
	list-style: outside none none;
	margin: 0 0 1rem 2rem; padding: 0;

	li {

		position:relative;
		&:before {
			position: absolute;
			font-family: 'skiset';
			content: '\ea10';
			color: $dark;
			margin: 0 .5rem 0 -2rem;
		}

		&.mobile:before { content: '\e91d'; }
		&.phone:before { content: '\e90c'; }
		&.fax:before { content: '\e91d'; }
		&.calendar:before { content: '\e91f'; }
		&.mail:before { content: '\e91e'; }
	}

}

/* Nav slide
---------------------------------------------- */

.nav-slide {
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		transition: color .3s;
		cursor: pointer;
		background-color: $white;
		outline: none;
		border: 1px solid $stroke;
		text-decoration:none !important;
		border-radius: 1rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 2rem;
		height: 2rem;
		color: $black !important;
		transition: background .3s, border .3s;
		font-size: .875rem;
		flex-shrink: 0;

		&:hover {
			background-color: $light-grey;
			border-color: $stroke-hover;
		}
	}

	ul {
		margin: 0 .5rem;;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;

		li {
			position: relative;
			display: inline-block;
			margin: 2px 4px;
			width: 20px;
			height: 6px;

			a {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				outline: none;
				border-radius: 3px;
				background-color: $stroke;
				text-indent: -999em;
				cursor: pointer; /* make the text accessible to screen readers */
				position: absolute;
				overflow: hidden;
				transition: background 0.3s;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					height: 0;
					left: 0;
					width: 100%;
					background-color: $primary;
				}

				&:hover, &:focus {
					background-color: $stroke-hover;
				}
			}

			&.current a::after {
				height: 100%;
			}
		}
	}

	a, a:hover, a:focus, a:active
	{
		text-decoration: none;
	}
}



/* Back to top
------------------------------*/

#pageSize {
	position: fixed;
	top: 0;left: 0;
	background: #000;
	color: #FFF;
	font-size: 14px;
	padding: .125rem .5rem;;
	font-style: oblique;
	z-index: 1300;
}

.presentation #pageSize {
	display: none;
}

#back-to-top {
    position: fixed;
    top: 50%;
    right: 40px;
	transform: translateY(-50%);
    z-index: 9999;
    width: 60px;
    height: 60px;
    background: $primary;
    color: #FFF;
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    transition: opacity 0.2s ease-out;
    display: none;
	justify-content:center;
	align-items:center;

	&:before {
		font-family: 'skiset';
		content: '\e902';
		font-size: 1rem;
		margin-bottom:.25rem;
	}

	&:hover {
		background: $dark-primary;
	}

	&.show {
		display: flex;
	}
}


/* Alert
------------------------------*/

.alert p {
	margin: 0;
}