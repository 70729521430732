@mixin style-box-sizing($type) { 
	-webkit-box-sizing: $type;
		-moz-box-sizing: $type;
			box-sizing: $type; 
} 

@mixin style-box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	   -moz-box-shadow:$shadow;
		    box-shadow: $shadow;
			
}

@mixin style-appearance($appearance) { 
	-webkit-appearance: $appearance;
		-moz-appearance: $appearance;
			appearance: $appearance;
} 

@mixin style-border-radius ($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin style-transition($transition) {
		 transition: $transition; 
	-moz-transition: $transition;
 -webkit-transition: $transition;
}

@mixin style-transform-rotate($transform) {
	-webkit-transform: rotate($transform);
		    transform: rotate($transform);
}

@mixin style-transform-origine($transform) {
	-webkit-transform-origin: $transform;
	        transform-origin: $transform;
}

@mixin style-transform-scale($transform) {
	-webkit-transform: scale($transform);
		    transform: scale($transform);
}

@mixin style-transform($transform) { 
	-webkit-transform: $transform;  
		    transform: $transform;
} 

@mixin style-user-select($user-select) { 
	-webkit-user-select: $user-select;
	   	-moz-user-select: $user-select;
		-ms-user-select: $user-select;
			user-select: $user-select;
} 