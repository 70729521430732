.home-wrapper {
	width: 100%;
	height: 100vh;
	//max-height: -webkit-fill-available;

	position: relative;

	background-color:#4291c9;
	background-size: cover;
	background-position: top right;
	background-repeat:  no-repeat;
	background-attachment: scroll;

	overflow: hidden;
	display:flex;
	align-items: stretch;
	justify-content: center;
	margin: 0;
	margin-top: -100px;

	.container {
		margin: auto;
		position:relative;
	}

	.no-header & {
		margin-top: 0;
	}

	&.desktop {
		min-height: 790px;
	}
	&.mobile {
		min-height: 400px;
	}

	&.cloded {
		min-height: 400px;
		flex-direction: column;
		justify-content: flex-end;
	}

	&.noslider {

		background-image: url("#{mediaResizeUrl('media-gallery/skiset/bg/media-671f62a31cbc4.jpg')}");
		
		@include media-breakpoint-down('lg') {
			background-image: url("#{mediaResizeUrl('media-gallery/skiset/bg/media-671f65bce3ae5.jpg')}");
		}
		@include media-breakpoint-down('md') {
			background-image: url("#{mediaResizeUrl('media-gallery/skiset/bg/media-671f661f1027e.jpg')}");
		}
		@include media-breakpoint-down('sm') {
			background-image: url("#{mediaResizeUrl('media-gallery/skiset/bg/media-671f668510f67.jpg')}");
		}
		@include media-breakpoint-down('xs') {
			background-image: url("#{mediaResizeUrl('media-gallery/skiset/bg/media-671f66efb00eb.jpg')}");
		}
	}
}

/* arguments
---------------------------------- */

.home-arguments {
	display: flex;
	flex-wrap: wrap;

	> div {
		text-align:center;
		position:relative;
		padding: 0 3rem;
		margin: 6rem 0 0;
		flex: 1 1 25%;
		width: 25%;
		display: flex;
		flex-direction: column;

		&:before {
			color:$red;
			font-size: 10rem;
			font-weight: 700;
			position: absolute;
			left: 50%;
			top:0;
			opacity: .1;
			line-height:1;
			transform: translateY(-45%) translateX(-50%);
		}

		&:nth-child(1):before {content:"01"}
		&:nth-child(2):before {content:"02"}
		&:nth-child(3):before {content:"03"}
		&:nth-child(4):before {content:"04"}
	}

	.title {
		font-weight: 700;
		text-transform: uppercase;
		font-size: 1.125rem;
		margin-bottom: 1.5rem;
		line-height: 1.2;

		span {
			display:block;
			color:$red;
		}
	}

	p {
		color:$dark-grey;
		font-size:.9rem;
	}



	@include media-breakpoint-down('lg') {
		> div {
			flex: 1 1 50%;
			width: 50%;
		}
	}

	@include media-breakpoint-down('sm') {
		> div {
			flex: 1 1 100%;
			width: 100%;
			padding: 0 1rem;
		}
	}
}