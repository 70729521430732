
#jqMessage { 
	position: fixed; 
	z-index: 20000; 
	bottom: 0px; left: 0px; 
	width: 100%; 
	box-sizing: border-box;
	display: none; 
	background-repeat: no-repeat;
	background-position: 100% 50%;
	display: flex;
	align-items: center;
	
	&.info { background-image: url("../../assets/images/messages/info.png");} 
	&.error { background-image: url("../../assets/images/messages/error.png");}
	&.caution { background-image: url("../../assets/images/messages/caution.png");}
	
	div {
		color: #FFF;
		font-size: 1.2rem; 
		font-weight: normal; 
		padding: 1rem 140px 1rem 1rem;
	}
}