.flash-sell {
	position:fixed;
	bottom:0; right: 0; left:0; width: 100%;
	border-top: 5px solid #15202b;
	background: #15202b url('#{$asset-path}images/bg/fs.png') no-repeat scroll 0 0;
	background-size: cover;
	z-index: 10;
	
	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.flash-explain {
		color: $white;
		padding: 1.5rem 2rem 1.5rem 8rem; 
		background-image: url('#{$asset-path}images/bg/flashsell.png');
		background-repeat: no-repeat;
		background-position: 0 50%;
		min-height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		&.fr {
			background-image: url('#{$asset-path}images/bg/flashsell-fr.png');
		}
		
		div {
			margin-bottom: .25rem;
		}
	}
	.title {
		font-size: 1.5rem;
		line-height: 1;
	}
	
	.content {
		font-size: 1rem;
		margin-bottom: .25rem;
	}
	
	.asterisk {
		font-size:.875rem;
		font-style:oblique;
		font-weight: 300;
		-text-align: right;
	}
	
	.flash-count {
		display: flex;
		
		div {
			width: 4rem;
			height: 4rem;
			background: $white;
			color: #15212c;
			border-radius: 2rem;
			font-size: 1.8rem;
			margin-left:1rem;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			
			span {
				display: block;
				font-size: .875rem;
			}
		}
	}
	
	
	@include media-breakpoint-down('md') {

		.content {
			display: none;
		}
		
		.flash-count {
			display: none;
		}
	} 
}