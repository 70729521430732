/* Home Hook
----------------------------------- */

.hook-wrapper {
	position: relative;
		
	
	.hook {
		color: #FFF;
		text-align: center;
		padding: 1rem;
		display: flex;
		flex-direction:column;
		justify-content: center;
		align-items: center;
		text-shadow: 1px 1px 1px rgba(0,0,0,.2);
			
		
		.title {
			font-style: oblique;  
			font-size: 2.5rem;
			font-weight: 600;
			line-height: 1.2;
			margin-bottom: .5rem;
		}
		
		.content {
			font-style: oblique; 
			font-size: 1.4rem;
			font-weight: 400;
			line-height: 1;
			margin: .5rem 0;
		} 
		.discount {
			
			margin-top: .5rem;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.4rem;
			font-weight: 400;
			
			span {
				margin: 0 .5rem;
				font-size: 2.5rem;
				font-weight: 600;
			}
		} 
		
		+ .hook {
			display: none;
		}
	}
}

@include media-breakpoint-down('lg') {

	.hook-wrapper {
		display: none;
	}
} 