.sk-card {
	display: flex;
	flex-direction: column;
	
	.card-title {
		font-size : 1rem;
		color: $black;
		font-weight: 700;
		margin-bottom: .25rem;

		span {
			color: $dark-grey;
			font-weight: 400;
		}
	}
	
	.card-content {
		font-size : .875rem;
		color: $dark-grey;
		font-weight: 500;
		
		b, span {
			color: $black;
		}
	} 
	
	.card-link {
		margin-top: 1rem;

		a {
			margin: 0;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			border: 1px solid $stroke;
			border-radius: .5rem;
			color: $black;
			font-size: .875rem;
			font-weight: 500;
			text-decoration: none;
			padding: .5rem;
			background: $white;
			
			+ a {
				margin-left: .25rem;
			}
		}
	}
}