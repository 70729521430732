.bgslider-wrapper {
	position: relative;
}

.bgslider-container {
	z-index: 1;
	width: 100%;
	height: 100%;
	display:flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
}

.bgslider-slider {
	z-index: 0;
}

.bgslider-slider,
.bgslider-overlay,
.bgslider-slide,
.bgslider-slide-inner {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	border: none;
	padding: 0;
	margin: 0;
	width: 100%; height: 100%
}

.bgslider-overlay {
	opacity: .5;
    background: transparent url('#{$asset-path}images/bg/overlays/02.png') center center repeat;
}

.bgslider-slide,
.bgslider-slide-inner {
	background: transparent center center no-repeat;
	-webkit-transform: translateZ(0);
			transform: translateZ(0);
}
.bgslider-slide::before {
	content: '';
	position:absolute;
	left:0;right:0;bottom:0;
	width: 100%;
	height: 100px;
	transform: rotate(180deg);
	background: transparent url('#{$asset-path}svg/mounts.svg') top right no-repeat;
	background-size: 100% 100%;
	z-index:10;
}

.bgslider-slide.brand::after {
	position:absolute; right:120px; bottom: 0;
	content:' ';
	-webkit-box-shadow: -5px -5px 10px -2px rgba(0,0,0,0.1); 
	box-shadow: -5px -5px 10px -2px rgba(0,0,0,0.1);
	width: 130px; height: 120px; z-index:5;
	background-color: #DDD;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 80%;
	padding-bottom:50px;
}

.bgslider-slide.brand-dynastar::after {
	background-image: url('#{$asset-path}images/bg/brds/dynastar.png');
}
.bgslider-slide.brand-salomon::after {
	background-image: url('#{$asset-path}images/bg/brds/salomon.png');
}
.bgslider-slide.brand-rossignol::after {
	background-image: url('#{$asset-path}images/bg/brds/rossignol.png');
}
.bgslider-slide.brand-nordica::after {
	background-image: url('#{$asset-path}images/bg/brds/nordica.png');
}
.bgslider-slide.brand-atomic::after {
	background-image: url('#{$asset-path}images/bg/brds/atomic.png'); 
}
.bgslider-slide.brand-head::after {
	background-image: url('#{$asset-path}images/bg/brds/head.png'); 
}
.bgslider-slide.brand-blizzard::after {
	background-image: url('#{$asset-path}images/bg/brds/blizzard.png'); 
}

/*************************
    Transitions
 *************************/

/* fade */
.bgslider-transition-fade,
.bgslider-transition-fade2 {
    opacity: 0;
}

.bgslider-transition-fade-in,
.bgslider-transition-fade2-in {
    opacity: 1;
}

.bgslider-transition-fade2-out {
    opacity: 0;
}