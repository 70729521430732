body.loading:after {
	/* with no content, nothing is rendered */
	content: "";
	position: fixed;
	/* element stretched to cover during rotation an aspect ratio up to 1/10 */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	pointer-events: all; 
	/* background */
	background: rgba(255,255,255,0.6);

}