.card-profil {
	.card-header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		
		.card-illustr {
			margin-right: 15px;
			background: $stroke;
			width :60px; height: 60px;
			border-radius: 30px;
			overflow: hidden;
			display: flex;
			flex-direction:row ;
			justify-content: center;
			align-items: flex-end;
			
			span {
				font-size: 3rem;
				color:#FFF
			}
		}
		
		.card-ident {
			> * {
				font-size: .875rem;
				display:block;
			}
			span.skier {
				font-size: 1rem;
				font-weight: 500;
			}
			em {
				color: $dark-grey;
			}
		}
		
		.dropdown {
			flex-grow: 1;
			text-align: right;
			align-self: flex-start;
			
			> a {
				color: $black;
				text-decoration: none;
			}
		}
	}
	
	.measure-specs {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		
		> div {
			margin: 1rem 0;
			padding: 0;
			align-self: flex-end;
			width: 33.3%;
				
			span {
				display: block;
				font-size: 2rem;
				text-align:center;
				
				&.leg {
					font-size: .75rem;
					padding: .25rem 0;
					color: $dark-grey;
				}
			}
		}
	}
	
	.measure-add  {
		display: flex;
		justify-content: center;
		margin-top: 2rem;
		color: $dark-grey;
		font-size: .75rem;
	}
}