.resa-wrapper {
	width: 100%;  
	height: calc(100vh - 100px - 54px);    // header & bread
	min-height: 700px;  
	position: relative;
	
	background: #5494db url('#{$asset-path}images/bg/book.jpg') no-repeat scroll 0 0;
	background-size : cover;
	
	overflow: hidden;
	display:flex;
	align-items: stretch;
	justify-content: center;
	
	.container {
		
		margin: auto;
		position:relative;
	}
}

.resa-booking {
	position: relative;
	margin-bottom: 2rem;
	display: flex;
	align-items: flex-start;
	
	.illustr {
		width: 180px;
		height: 180px;
		flex-basis: 180px;
		flex-shrink: 0;
		background-size: cover;
		border-radius: 2rem;
	}
	
	.sk-card  {
		flex: 1;
		margin-left: 2rem;
		font-size: 1rem;
	}
	
	.card-content {
		font-size : 1rem;
	} 
	
	.card-link {
		text-align: right;
	}
	
	.cadd-quote {
		border-top: 1px solid $stroke;
		margin: 1rem 0;
		padding-top: 1rem;
	}
	
	@include media-breakpoint-down('sm') {
		.illustr {
			display:none;
		}
		.sk-card  {
			margin-left:0;
		}
	}
}

.resa-pack {
	margin-bottom: 3rem;
	
	.title {
		margin-bottom: 2rem;
		display: flex;
		align-items: center;
		
		> span {
			font-weight: 700;
			font-size: 1.2rem;
			margin-right: 1rem;
			color: $red;
		}
	}
	
	.pack-ident {
		display:none;
	}
	
	&.ident {
		.pack-ident {
			display:block;
		}
	}
}

.resa-messages {
	display:flex;
	flex-direction: column;
	margin: 3rem 0;
	
	.message-card {
		flex-basis: 50%;
		width: 50%;
		background: $light-grey;
		border-radius: 2rem;
		box-shadow: $shadow_100;
		padding: 1rem 2rem;
		margin: 1rem 0;
		position:relative;
		
		&::before  {
			content: "";
			width: 20px;
			height: 20px;
			position: absolute;
			top: 2rem;
			left: -10px;
			background: darken($light-grey, 10);
			transform:rotate(45deg);
			z-index: -1;
		} 
		
		.title {
			font-weight: 600;
		}
		.content {
			margin: 1rem 0;
		}
		
		.date {
			font-size: .875rem;
			text-align: right;
		}
		
		&.customer {
			background: $green;
			align-self: flex-end;
			color: $white;
			
			&::before  {
				left: auto;
				right: -10px;
				background: darken($green, 10);
			}
		}
	}
	
	@include media-breakpoint-down('md') {
		.message-card {
			flex-basis: 100%;
			width: 100%;
		}
	}
}