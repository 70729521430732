.callout {
	padding:1.25rem;
	margin-top:1.25rem;
	margin-bottom:1.25rem;
	border:1px solid #eee;
	border-left-width:.25rem;
	
	p:last-child {
		margin-bottom:0
	}
}

.summary {
	
	.line {
		display: flex;
		font-size: 1rem;
		justify-content: space-between;
		color: $dark-grey;
		
		div:last-child {
			color: $black;
		}
	}
} 